const onNewConversation = (newConversation, props) => {
  const {
    inboxFolder,
    conversationStatusFilter,
    conversations,
    myDepartmentsIDs,
    currentUser,
    projectId,
  } = props;

  const {
    conversation_id,
    conversation_meta,
    conversation_meta: {
      conversation_status,
      department_id_assigned,
      assignee,
      project_id: conversationProjectId,
    },
  } = newConversation;

  if (Number(projectId) !== Number(conversationProjectId)) {
    return;
  }
  // External
  const isCurrentFilter = conversation_status === conversationStatusFilter;
  const isAssignedToMe = assignee === currentUser.user_id;
  const isAssignedToThisDepartment = myDepartmentsIDs.includes(department_id_assigned);
  const isUnassigned = assignee === -1;
  const folderYou = inboxFolder.submenu === 'External' && inboxFolder.file.value === 'you';
  const folderUnassigned =
    inboxFolder.submenu === 'External' && inboxFolder.file.value === 'unassigned';
  const folderAll = inboxFolder.submenu === 'External' && inboxFolder.file.value === 'external_all';

  const conversationIsInList = conversations.some(
    (item) => item.conversation_id === conversation_id
  );

  // Internal
  const isInternalConversation =
    inboxFolder.submenu === 'Internal' && inboxFolder.file.value === 'internal_all';

  // UPDATE COUNT
  if (isCurrentFilter) {
    if (isAssignedToMe && !isInternalConversation) {
      props.incrementYou();
      props.addToYouUnread({ conversation_id });
    }
    if (isAssignedToThisDepartment && isUnassigned) {
      props.incrementUnassigned();
      props.addToUnassignedUnread({ conversation_id });
    }
    if (isAssignedToThisDepartment && !isInternalConversation) {
      props.incrementAll();
      props.addToExternalAllUnread({ conversation_id });
    }

    if (isInternalConversation) {
      props.incrementInternalAll();
      props.addToInternalAllUnread({ conversation_id });
    }
  }

  // UPDATE LIST
  if (isCurrentFilter) {
    if (conversationIsInList) {
      props.updateConversationInList({ conversation_id, conversation_meta });
    } else if (
      (folderYou && isAssignedToMe) ||
      (folderUnassigned && isAssignedToThisDepartment && isUnassigned) ||
      (folderAll && isAssignedToThisDepartment)
    ) {
      props.setNewConversation({ conversation_id, conversation_meta });
    }
  }
};

export default onNewConversation;

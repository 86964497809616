import React, { Component } from 'react';
import Axios from 'axios';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import DrawerRight from 'pages/Flow/components/Dialog';
import NameInput from 'pages/Flow/components/NameInput';
import Tabs from 'components/common/TabBar';
import DropDown from 'components/common/DropDown';
import DeleteButton from '../components/DeleteButton';
import ConnectionsWrapper from 'pages/Flow/containers/ConnectionsWrapper';
import InputText from 'components/common/input/InputText';
import { ThemeContext } from 'pages/Flow/utils/Theme';
import Checkbox from 'components/common/LabeledCheckbox/Checkbox';
import './EventDialog.scss';
import languageMap from '../../../../../../../../Language/Language';

class TimeDeltaSettings extends Component {
  constructor(props) {
    super(props);

    this.unitOptions = [
      {
        label: 'minutes',
        value: 'minutes',
      },
      {
        label: 'hours',
        value: 'hours',
      },
      {
        label: 'days',
        value: 'days',
      },
    ];

    this.debouncedSync = debounce(this.syncStateWithServer, 300);

    const trigger_object = JSON.parse(this.props.trigger_object);

    if (isEmpty(trigger_object)) {
      this.state = {
        start_delta_value: '0',
        start_delta_unit: 'days',
        repeat_value: '0',
        repeat_unit: 'days',
        start_hour: '08:00',
        use_start_hour: false,
      };

      this.debouncedSync();
    } else {
      this.state = trigger_object;
    }
  }

  syncStateWithServer = () => {
    let params = {
      event_id: this.props.eventId,
      object: JSON.stringify(this.state),
    };

    Axios.post('/internal_api/event/update_trigger', params);
  };

  handleDeltaUnitChange = (value) => {
    this.setState({ start_delta_unit: value }, this.debouncedSync);
  };

  handleRepeatUnitChange = (value) => {
    this.setState({ repeat_unit: value }, this.debouncedSync);
  };

  handleValueChange = (key, value) => {
    this.setState({ [key]: value }, this.debouncedSync);
  };

  handleUseStartHourChange = (event) => {
    const value = event.target.checked;

    this.setState({ use_start_hour: value }, this.debouncedSync);
  };

  render() {
    const {
      use_start_hour,
      start_hour,
      start_delta_unit,
      start_delta_value,
      repeat_unit,
      repeat_value,
    } = this.state;

    let startHourInput;

    if (use_start_hour) {
      startHourInput = (
        <InputText
          type="time"
          style={{ width: 150, margin: '20px 0px 0px 20px' }}
          value={start_hour}
          onValueChanged={this.handleValueChange}
          name="start_hour"
        />
      );
    }

    return (
      <>
        <h3 className="EventDialog_label">Delta</h3>
        <div className="EventDialog_row">
          <DropDown
            value={start_delta_unit}
            defaultText="days"
            options={this.unitOptions}
            onSelect={this.handleDeltaUnitChange}
            containerStyle={{
              width: 100,
            }}
          />
          <InputText
            type="number"
            value={start_delta_value}
            onValueChanged={this.handleValueChange}
            name="start_delta_value"
          />
        </div>

        <h3 className="EventDialog_label">Repeat</h3>

        <div className="EventDialog_row">
          <DropDown
            value={repeat_unit}
            defaultText="days"
            options={this.unitOptions}
            onSelect={this.handleRepeatUnitChange}
            containerStyle={{
              width: 100,
            }}
          />
          <InputText
            type="number"
            value={repeat_value}
            onValueChanged={this.handleValueChange}
            name="repeat_value"
          />
        </div>

        <div className="EventDialog_StartHourCheckbox">
          <Checkbox checked={use_start_hour} onChange={this.handleUseStartHourChange} />

          <h3>Start Hour</h3>
        </div>
        <div className="EventDialog_StartHourInput">{startHourInput}</div>
      </>
    );
  }
}

export default class EventDialog extends Component {
  static contextType = ThemeContext;

  constructor(props) {
    super(props);

    this.state = {
      event_name: props.settings.event_name,
      event_type: props.settings.event_type,
      event_trigger_json_object: props.settings.event_trigger_json_object,
    };

    this.eventTypeOptions = this.props.eventTypes.map((row) => {
      return {
        label: row.event_type_name,
        value: row.event_type_id,
      };
    });

    this.debouncedSync = debounce(this.syncStateWithServer, 100);
  }

  handleTypeChange = (value) => {
    const { settings, updateEventInCanvasContext } = this.props;
    const newSettings = {
      ...settings,
      event_type: value,
    };

    this.last_event_type = this.state.event_type;
    this.setState({ event_type: value });
    updateEventInCanvasContext('event', newSettings);
    this.debouncedSync();
  };

  syncStateWithServer = () => {
    let params = {
      project_id: this.props.projectId,
      event_id: this.props.eventId,
      properties: this.state,
    };

    Axios.put('/internal_api/event', params).then((response) => {
      if (response.data.status === 'error') {
        this.setState({ event_type: this.last_event_type });
        alert(response.data.message);
      }
    });
  };

  deleteNode = () => {
    this.props.onClose();
    this.props.deleteNode(this.props.nodeId);
  };

  render() {
    const { nodeId, appId, onClose, onNameChange, objectName, settings } = this.props;

    let nodeIdLabel, Settings;
    const { event_type } = this.state;

    // Rest | UiPath
    // eslint-disable-next-line eqeqeq
    if (event_type == 2 || event_type == 3) {
      nodeIdLabel = <h3 style={{ marginLeft: 20 }}>Node ID : {nodeId}</h3>;
    }

    // Time from start / Inactivity
    // eslint-disable-next-line eqeqeq
    if (event_type == 6 || event_type == 7) {
      Settings = (
        <TimeDeltaSettings
          trigger_object={settings.event_trigger_json_object}
          eventId={settings.event_id}
        />
      );
    }

    return (
      <DrawerRight
        onClose={onClose}
        headerView={
          <>
            <NameInput
              appId={appId}
              id={settings.event_id}
              type="event"
              nodeId={nodeId}
              onNameChange={onNameChange}
              value={objectName}
            />
            <DeleteButton title={languageMap.deleteEvent} type="event" onClick={this.deleteNode} />
          </>
        }
        contentView={
          <div className="EventDialog">
            <Tabs>
              <div label={languageMap.settings}>
                <DropDown
                  value={event_type}
                  options={this.eventTypeOptions}
                  defaultText="Select event type"
                  onSelect={this.handleTypeChange}
                />
                {nodeIdLabel}
                {Settings}
              </div>
              <div label={languageMap.connectionsLabel}>
                <ConnectionsWrapper nodeId={nodeId} />
              </div>
            </Tabs>
          </div>
        }
      />
    );
  }
}

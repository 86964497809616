import React, { useState } from 'react';
import Axios from 'axios';
import { Dropdown } from 'semantic-ui-react';
import Loader from 'components/common/Loader';
import './Auth.scss';
import { useNavigate } from 'react-router-dom';

const RegisterForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [language, setLanguage] = useState('en');
  const [email, setEmail] = useState('');
  const [confirmedEmail, setConfirmedEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onChangeLanguage = (e, { value }) => {
    setLanguage(value);
  };

  const handleRegister = () => {
    setErrors({});

    if (!isValidName(firstName)) {
      alert('First Name is required');
      return;
    }
    if (!isValidName(lastName)) {
      alert('Last Name is required');
      return;
    }
    if (!isValidName(displayName)) {
      alert('Display Name is required');
      return;
    }
    if (!isValidEmail(email)) {
      alert('Invalid email');
      return;
    }
    if (email !== confirmedEmail) {
      alert('Emails do not match');
      return;
    }
    if (!isValidPassword(password)) {
      alert('Invalid password');
      return;
    }
    if (password !== confirmedPassword) {
      alert('Passwords do not match');
      return;
    }

    setLoading(true);

    Axios.post('/internal_api/register', {
      first_name: firstName?.trim(),
      name: lastName?.trim(),
      display_name: displayName?.trim(),
      email: email?.trim(),
      language: language,
      password: password,
      password_confirmation: confirmedPassword,
    })
      .then(() => {
        alert('Register succesful!');
        navigate('/login');
      })
      .catch((error) => {
        console.log(error);
        setErrors(error.response?.data?.errors || {});
      })
      .finally(() => setLoading(false));
  };

  const isValidName = (name) => {
    return name.length > 0;
  };

  const isValidEmail = (email) => {
    const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, 'gm');
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    return password.length >= 8;
  };

  let errorsObject = null;
  if (Object.keys(errors).length > 0) {
    errorsObject = (
      <ul style={{ color: 'red' }}>
        {Object.keys(errors).map((key) => (
          <li>{errors[key]}</li>
        ))}
      </ul>
    );
  }

  return (
    <>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span className="FormLabel dm-sans-500">First name</span>
          {!isValidName(firstName) && (
            <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
              * (required)
            </span>
          )}
        </div>
        <input
          name="first_name"
          className="Input RegisterInput dm-sans-400"
          placeholder="First name"
          type="text"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </div>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span className="FormLabel dm-sans-500">Last name</span>
          {!isValidName(lastName) && (
            <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
              * (required)
            </span>
          )}
        </div>
        <input
          name="last_name"
          className="Input RegisterInput dm-sans-400"
          placeholder="Last name"
          type="text"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </div>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span>Display name</span>
          {!isValidName(displayName) && <span className="FormLabelRequired">* (required)</span>}
        </div>
        <input
          name="display_name"
          className="Input RegisterInput dm-sans-400"
          placeholder="Display name"
          type="text"
          value={displayName}
          onChange={(e) => setDisplayName(e.target.value)}
        />
      </div>
      <div className="FormRow">
        <span className="FormLabel dm-sans-500">Language</span>
        <Dropdown
          className="Dropdown"
          selection
          value={language}
          options={[
            {
              key: 'en',
              text: 'English',
              value: 'en',
            },
            {
              key: 'ro',
              text: 'Romanian',
              value: 'ro',
            },
          ]}
          onChange={onChangeLanguage}
        />
      </div>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span className="FormLabel dm-sans-500">Email</span>
          {!isValidEmail(email) && (
            <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
              * (invalid)
            </span>
          )}
        </div>
        <input
          name="email"
          autoComplete="email"
          className="Input RegisterInput dm-sans-400"
          placeholder="Your email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span className="FormLabel dm-sans-500">Confirm Email</span>
          {email !== confirmedEmail && (
            <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
              * (invalid)
            </span>
          )}
        </div>
        <input
          name="confirmed_email"
          autoComplete="email"
          className="Input RegisterInput dm-sans-400"
          placeholder="Your email"
          type="email"
          value={confirmedEmail}
          onChange={(e) => setConfirmedEmail(e.target.value)}
        />
      </div>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span className="FormLabel dm-sans-500">New password</span>
          {!isValidPassword(password) && (
            <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
              * (required)
            </span>
          )}
        </div>
        <input
          name="password"
          autoComplete="current-password"
          className="Input RegisterInput dm-sans-400"
          placeholder="Your password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div className="FormRow">
        <div className="RegisterFormLabel dm-sans-500">
          <span className="FormLabel dm-sans-500">Confirm Password</span>
          {password !== confirmedPassword && (
            <span className="FormLabel dm-sans-500" style={{ color: '#0530F9' }}>
              * (invalid)
            </span>
          )}
        </div>
        <input
          name="confirm_password"
          autoComplete="current-password"
          className="Input RegisterInput dm-sans-400"
          placeholder="Your password"
          type="password"
          value={confirmedPassword}
          onChange={(e) => setConfirmedPassword(e.target.value)}
        />
      </div>

      {errorsObject}

      {loading && <Loader style={{ width: '100%', height: '100%', marginTop: 25 }} />}
      {!loading && (
        <button className="Submit dm-sans-700" onClick={handleRegister}>
          Register
        </button>
      )}
    </>
  );
};

export default RegisterForm;

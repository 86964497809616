import React from 'react';

import DrawerRight from 'pages/Flow/components/Dialog';

import './InformationWindow.scss';

const InformationWindow = (props) => {
  const { clickedIntegration, InformationWindowText, close } = props;

  return (
    <DrawerRight
      onClose={close}
      headerView={<div className="MSTeamsConfigurationDialog_header">{clickedIntegration}</div>}
      contentView={
        <div className="InformationWindow">
          <h3 className="information-window-text">{InformationWindowText}</h3>
        </div>
      }
    />
  );
};

export default InformationWindow;

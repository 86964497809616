import React from 'react';
import { styles } from './ImageMessageStyles.js';
import { openImageModal } from 'pages/Flow/components/ModalContainer/ImageModal.js';

export default function ImageMessage(props) {
  const { imageUrl } = props;

  const getStyles = (element) => {
    return styles[element];
  };

  return (
    <div style={getStyles('ImageFileMessage')} onClick={() => openImageModal(imageUrl)}>
      <img style={getStyles('ImageFileMessage_Image')} src={imageUrl} alt="File Message" />
    </div>
  );
}

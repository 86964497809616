import React from 'react';

export function FlexRow(props) {
  const containerStyle = {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: 15,
    width: '100%',
    ...props.style,
  };

  return <div style={containerStyle}>{props.children}</div>;
}

export function FlexColumn(props) {
  const containerStyle = {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'flex-start',
    width: '100%',
    ...props.style,
  };

  return <div style={containerStyle}>{props.children}</div>;
}

export function Label(props) {
  const labelStyle = {
    fontSize: 18,
    color: '#333',
    width: props.width,
    fontWeight: '700',
    ...props.style,
  };

  return <span style={labelStyle}> {props.text} </span>;
}

export function Input(props) {
  const inputStyle = {
    width: 400,
    backgroundColor: '#EAEAE8',
    fontSize: 18,
    color: '#333',
    border: 'none',
    padding: '10px 20px',
    ...props.style,
  };

  return (
    <input
      style={inputStyle}
      value={props.value}
      placeholder={props.placeholder}
      onChange={(e) => props.onChange(e.target.value)}
      onBlur={props.onBlur}
    />
  );
}

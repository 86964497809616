import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';

import Title from 'pages/ProjectDetails/components/Title';
import Integration from 'components/common/Integration/Integration';
import WebchatConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/WebchatConfigurationDialog';
import SipConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/SipConfigurationDialog';
import FacebookConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/FacebookConfigurationDialog';
import MSTeamsConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/MSTeamsConfigurationDialog';
import InformationWindow from './components/InformationWindow';
import GenerativeAIConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/GenerativeAIConfigurationDialog';
import OCRConfigurationDialog from 'pages/ProjectDetails/components/Integrations/components/OCRConfigurationDialog';

import './Integrations.scss';
import languageMap from 'Language/Language';
import EmailConfigurationDialog from './components/EmailConfiguration/EmailConfigurationDialog';

export const REST = 1;
export const CHANNEL_WEB = 2;
export const EMAIL = 10;
const INTEGRATIONS_ORDER = [REST, CHANNEL_WEB, 4, EMAIL, 13, 5, 7, 9, 14, 15, 17, 18];
export const integrationTitle = {
  [REST]: languageMap.rest,
  [CHANNEL_WEB]: languageMap.web,
  4: languageMap.sip,
  5: languageMap.meta,
  6: languageMap.zendesk,
  7: languageMap.microsoftTeams,
  9: languageMap.restApiClient,
  [EMAIL]: languageMap.email,
  12: languageMap.instagram, // for channels
  13: languageMap.whatsapp,
  14: languageMap.generativeAi,
  15: languageMap.ocr,
  // 16: languageMap.sms,
  17: languageMap.customchat,
  18: languageMap.customcrm,
};

let configurationScreen = null;

const Integrations = (props) => {
  const [integrationTypes, setIntegrationTypes] = useState({});
  const [showIntegrationConfigurationForTypeId, setShowIntegrationConfigurationForTypeId] =
    useState(null);

  const { projectId } = props;

  const fetchIntegrations = useCallback(async () => {
    try {
      const response = await Axios.get('/internal_api/project/integrations', {
        params: { projectId: projectId },
      });
      mapIntegrations(response.data);
    } catch (error) {
      console.error('Error fetching integrations:', error);
    }
  });

  const onIntegrationStatusChange = async (integrationTypeId, isActive) => {
    try {
      setIntegrationTypes({
        ...integrationTypes,
        [integrationTypeId]: { ...integrationTypes[integrationTypeId], isActive },
      });
      await Axios.post('/internal_api/project/integration/update', {
        projectId: projectId,
        typeId: integrationTypeId,
        column: 'is_active',
        value: isActive,
      });
      fetchIntegrations();

      if (isActive && needsExtraConfiguration(integrationTypeId)) {
        setShowIntegrationConfigurationForTypeId(integrationTypeId);
      }
    } catch (error) {
      console.error('Error updating integration status:', error);
    }
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  const mapIntegrations = (data) => {
    const mappedIntegrations = {};
    Object.entries(data).forEach(([key, value]) => {
      if (integrationTitle[key]) {
        mappedIntegrations[key] = {
          title: integrationTitle[key],
          ...value,
        };
      }
    });
    setIntegrationTypes(mappedIntegrations);
  };

  const needsExtraConfiguration = (integrationTypeId) => {
    if (integrationTypeId) {
      switch (parseInt(integrationTypeId, 10)) {
        case 2: // Web
        case 4: // SIP
        case 5: // Meta
        case 7: // Microsoft Teams
        case 9: // REST API Client
        case 10: // Email
        case 13: // WhatsApp
        case 14: // Custom Chat
        case 15: // Custom CRM
        case 17: // Generative AI
        case 18: // OCR
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  const handleCloseConfig = () => {
    setShowIntegrationConfigurationForTypeId(null);
  };

  const handleIntegrationClick = (integrationTypeId) => () => {
    if (integrationTypeId === null || integrationTypeId === undefined) {
      return;
    }
    if (!integrationTypes[integrationTypeId]?.isActive) {
      onIntegrationStatusChange(integrationTypeId, true);
      return;
    }
    if (needsExtraConfiguration(integrationTypeId)) {
      setShowIntegrationConfigurationForTypeId(integrationTypeId);
    }
  };
  // console.log(integrationTypes);
  const integrations = INTEGRATIONS_ORDER.map((integrationTypeId) => {
    const integration = integrationTypes[integrationTypeId];
    if (!integration) {
      return;
    }
    return (
      <Integration
        key={integrationTypeId}
        title={integration.title}
        integrationTypeId={integrationTypeId}
        active={integration.isActive}
        configurable={needsExtraConfiguration(integrationTypeId)}
        onChange={onIntegrationStatusChange}
        onClick={handleIntegrationClick(integrationTypeId)}
      />
    );
  });

  const getConfigurationScreen = () => {
    if (
      showIntegrationConfigurationForTypeId === null ||
      showIntegrationConfigurationForTypeId === undefined
    ) {
      return;
    }
    switch (parseInt(showIntegrationConfigurationForTypeId, 10)) {
      case 2: // Web
        configurationScreen = (
          <WebchatConfigurationDialog
            integrationTypeId={showIntegrationConfigurationForTypeId}
            projectId={projectId}
            close={handleCloseConfig}
          />
        );
        break;
      case 4: // SIP
        configurationScreen = (
          <SipConfigurationDialog projectId={projectId} close={handleCloseConfig} />
        );
        break;
      case 5: // Meta
        configurationScreen = (
          <FacebookConfigurationDialog projectId={projectId} close={handleCloseConfig} />
        );
        break;
      case 7: // Microsoft Teams
        configurationScreen = (
          <MSTeamsConfigurationDialog projectId={projectId} close={handleCloseConfig} />
        );
        break;
      case 9: // REST API Client
        configurationScreen = (
          <div>
            <InformationWindow
              clickedIntegration={languageMap.restApiClient}
              InformationWindowText={languageMap.informationWindowText}
              close={handleCloseConfig}
            />
          </div>
        );
        break;
      case 10: // Email
        configurationScreen = (
          <div>
            <EmailConfigurationDialog
              projectId={projectId}
              clickedIntegration={languageMap.email}
              close={handleCloseConfig}
            />
          </div>
        );
        break;
      case 13: // WhatsApp
        configurationScreen = (
          <div>
            <InformationWindow
              clickedIntegration={languageMap.whatsapp}
              InformationWindowText={languageMap.informationWindowText}
              close={handleCloseConfig}
            />
          </div>
        );
        break;
      case 17: // Custom Chat
        configurationScreen = (
          <div>
            <InformationWindow
              clickedIntegration={languageMap.customchat}
              InformationWindowText={languageMap.informationWindowText}
              close={handleCloseConfig}
            />
          </div>
        );
        break;
      case 18: // Custom CRM
        configurationScreen = (
          <div>
            <InformationWindow
              clickedIntegration={languageMap.customcrm}
              InformationWindowText={languageMap.informationWindowText}
              close={handleCloseConfig}
            />
          </div>
        );
        break;
      case 14: // Generative AI
        configurationScreen = (
          <GenerativeAIConfigurationDialog projectId={projectId} close={handleCloseConfig} />
        );
        break;
      case 15: // OCR
        configurationScreen = (
          <OCRConfigurationDialog projectId={projectId} close={handleCloseConfig} />
        );
        break;
      default:
        configurationScreen = null;
        break;
    }
    return configurationScreen;
  };

  return (
    <div className="Integrations">
      <Title title={languageMap.integrations} />
      <div className="Integrations_ScrollableContainer">
        <p className="Integrations_ScrollableContainer_Paragraph">
          {languageMap.integrationsHeaderText}
        </p>
        {integrations}
        {getConfigurationScreen()}
      </div>
    </div>
  );
};

export default Integrations;

import React from 'react';
import { produce } from 'immer';
import Axios from 'axios';
import { Dropdown } from 'semantic-ui-react';
import * as Sentry from '@sentry/react';

import Loader from 'components/common/Loader';
import DrawerRight from 'pages/Flow/components/Dialog';
import NameInput from 'pages/Flow/components/NameInput';
import Utterances from 'components/Utterances';
import Tabs from 'components/common/TabBar';
import ConnectionsWrapper from 'pages/Flow/containers/ConnectionsWrapper';
import DeleteButton from '../components/DeleteButton';

import LabeledCheckbox from 'components/common/LabeledCheckbox';
import SeparatorV2 from 'components/common/SeparatorV2';

import SectionTitle from 'components/common/SectionTitle';
import languageMap from 'Language/Language';

import './IntentDialog.scss';
import HelperTooltip from '../../../../../../../../components/HelperTooltip/HelperTooltip';

export default class IntentDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      utterances_by_language: {},
      selectedLanguage: '',
      ...props.settings,
    };

    this.options = ['match_all', 'is_supraintent', 'nlp_active', 'allow_one_entity_regex'];
  }

  componentDidMount() {
    const { appId, objectId, selectedLanguage } = this.props;
    this.setState({ isLoading: true, selectedLanguage });
    Axios.get('/internal_api/get_intent', {
      params: { app_id: appId, intent_id: objectId },
    })
      .then((response) => {
        this.setState({
          ...response.data,
          isLoading: false,
        });
      })
      .catch((e) => {
        console.error(e);
        this.setState({ isLoading: false });
      });
  }

  handleInputChange = (name) => (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    this.setState(
      produce((draft) => {
        draft[name] = value;
      })
    );

    if (this.options.indexOf(name) >= 0) {
      const payload = {
        app_id: this.props.appId,
        id: this.state.intent_id,
        value,
        option: name,
      };

      window.delayed_update('intent_options_' + name, () => {
        Axios.post('/internal_api/intent_set_option', payload);
      });
    }
  };

  deleteNode = () => {
    this.props.onClose();
    this.props.deleteNode(this.props.nodeId);
  };

  onChangeLanguage = (e, { value }) => {
    this.setState({ selectedLanguage: value });
  };

  updateUtterances = (utterances, has_more) => {
    const { selectedLanguage } = this.state;
    this.setState((draft) => {
      let utterancesData = draft['utterances_by_language'][selectedLanguage];
      if (!utterancesData) {
        Sentry.captureException('FAILURE - NO LANGUAGE IN INTENT');
        utterancesData = {};
      }
      utterancesData.has_more = has_more !== undefined ? has_more : utterancesData.has_more;
      utterancesData.utterances = utterances;
    });
  };

  renderContentView() {
    const { utterances_by_language, selectedLanguage } = this.state;
    const utterancesData = utterances_by_language[selectedLanguage] || {};
    return (
      <div className="content-view">
        <Tabs>
          <div className="Training" label={languageMap.training}>
            <SeparatorV2 top={10} bottom={10} />
            <SectionTitle text={languageMap.intentSettings} />
            <div className="Settings_Container">
              <div className="Settings_Container_Row">
                <LabeledCheckbox
                  className="LabeledCheckbox"
                  label={languageMap.defaultIntent}
                  onChange={this.handleInputChange('match_all')}
                  checked={!!this.state.match_all}
                />
                <HelperTooltip
                  style={{ marginLeft: 8 }}
                  title={languageMap.helperTextDefaultIntent}
                />
              </div>

              <div className="Settings_Container_Row">
                <LabeledCheckbox
                  label={languageMap.nlpActive}
                  onChange={this.handleInputChange('nlp_active')}
                  checked={!!this.state.nlp_active}
                />
                <HelperTooltip
                  style={{ marginLeft: 8 }}
                  title={
                    <div
                      color="inherit"
                      dangerouslySetInnerHTML={{ __html: languageMap.helperTextMachineLearning }}
                    />
                  }
                />
              </div>
            </div>
            <SeparatorV2 top={25} bottom={10} />

            <Utterances
              hasMore={utterancesData.has_more}
              appId={this.props.appId}
              entities={this.props.entities}
              intentId={this.state.intent_id}
              style={{ margin: 0, marginLeft: 25, width: 'calc(100% - 25px)' }}
              utterances={utterancesData.utterances}
              selectedLanguage={selectedLanguage}
              updateUtterances={this.updateUtterances}
            />
          </div>
          {this.props.hideConnections ? (
            <div label={languageMap.connections}>{languageMap.cantDisplayConnections}</div>
          ) : (
            <div label={languageMap.connections}>
              <ConnectionsWrapper nodeId={this.props.nodeId} />
            </div>
          )}
        </Tabs>
      </div>
    );
  }

  render() {
    const { languageOptions } = this.props;
    const { isLoading, selectedLanguage } = this.state;

    return (
      <DrawerRight
        className="IntentDialog"
        onClose={this.props.onClose}
        contentMenuName="Training phrases"
        headerView={
          !isLoading && (
            <>
              <NameInput
                appId={this.props.appId}
                id={this.state.intent_id}
                type="intent"
                nodeId={this.props.nodeId}
                onNameChange={this.props.onNameChange}
                value={this.props.objectName}
              />
              {languageOptions.length > 1 && (
                <Dropdown
                  className="languageDropdown"
                  selection
                  value={selectedLanguage}
                  options={languageOptions}
                  onChange={this.onChangeLanguage}
                />
              )}
              <DeleteButton
                title={languageMap.deleteIntent}
                type="intent"
                onClick={this.props.deleteNode ? this.deleteNode : null}
              />
            </>
          )
        }
        contentView={isLoading ? <Loader /> : this.renderContentView()}
      />
    );
  }
}

import React from 'react';

import { connect } from 'react-redux';

import { selectIsMobile } from 'store/crm/selectors';

import './ConversationSummary.scss';
import { ArrowBack, Close } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';

const ConversationSummary = ({
  subject,
  title,
  handleConversationDetailsButtonClick,
  isMobile,
  returnBackToConversationScreen,
}) => {
  const IdFeatureFlag = false;

  const handleCloseButtonClick = () => {
    handleConversationDetailsButtonClick();
  };

  return (
    <div className={`ConversationSummary ${isMobile ? 'ConversationSummary_mobile' : ''}`}>
      {isMobile && (
        <IconButton onClick={returnBackToConversationScreen} className="back_button" size="large">
          <ArrowBack />
        </IconButton>
      )}
      <div className="Title title-text">
        {title}
        {!isMobile && (
          <IconButton onClick={handleCloseButtonClick} className="close_button" size="small">
            <Close className="close_button_icon"/>
          </IconButton>
        )}
      </div>
      {IdFeatureFlag && (
        <div className="Id">
          <div className="label">ID</div>
          <div className="content">18985100000</div>
        </div>
      )}
      {subject && (
        <div className="Subject">
          <div className="label">Subject</div>
          <div className={`content ${subject ? '' : 'empty'}`}>{subject}</div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: selectIsMobile(state),
});

export default connect(mapStateToProps, null)(ConversationSummary);

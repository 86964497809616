import React, { Component } from 'react';
import Axios from 'axios';
import get from 'lodash/get';
import Loader from 'components/common/Loader';
import withNotifications from 'components/withNotifications';
import ConnectionsWrapper from 'pages/Flow/containers/ConnectionsWrapper';
import Tabs from '../../../../../../../../components/common/TabBar';
import DrawerRight from 'pages/Flow/components/Dialog';
import NameInput from 'pages/Flow/components/NameInput';
import DropDown from 'components/common/DropDown';
import DeleteButton from '../components/DeleteButton';
import { ThemeContext } from '../../../../../../utils/Theme';
import RestServiceConfiguration from '../../../../../Service/RestServiceConfiguration';
import EmailServiceConfiguration from '../../../../../Service/EmailServiceConfiguration';
import languageMap from '../../../../../../../../Language/Language';
import {
  EMAIL,
  REST,
} from '../../../../../../../ProjectDetails/components/Integrations/Integrations';

const INTEGRATIONS = [
  {
    label: languageMap.rest,
    value: REST,
  },
  {
    label: languageMap.email,
    value: EMAIL,
  },
];
class ServiceDialog extends Component {
  static contextType = ThemeContext;

  state = {
    integrationTypeId: undefined,
    isLoading: true,
    data: null,
  };

  componentDidMount() {
    const { serviceId, appId } = this.props;
    Axios.get('/internal_api/service', {
      params: { app_id: appId, service_id: serviceId },
    })
      .then((response) => {
        this.setState({
          integrationTypeId: get(response, 'data.serviceDetails.integration_type_id'),
          data: get(response, 'data.serviceDetails.data'),
          isLoading: false,
        });
      })
      .catch(() => this.setState({ isLoading: false }));
  }

  close = () => {
    this.props.closeIntentDialog();
  };

  setNewIntegrationType = (newType, callback) => {
    this.setState(
      {
        ...this.state,
        integrationTypeId: newType,
      },
      callback
    );
  };

  handleTypeChange = (type) => {
    if (type && type !== this.state.integrationTypeId) {
      this.setNewIntegrationType(type, () => {
        this.handleServiceDataChange(type, null);
      });
    }
  };

  handleServiceDataChange = (integrationTypeId, data) => {
    Axios.put('/internal_api/service', {
      app_id: this.props.appId,
      service_id: this.props.serviceId,
      type: integrationTypeId,
      data: data,
    })
      .then((response) => {
        console.log('Response for service sync', response);
      })
      .catch((error) => {
        console.log('Error while service syncing', error);
      });
  };

  deleteNode = () => {
    this.props.onClose();
    this.props.deleteNode(this.props.nodeId);
  };

  renderView = () => {
    const { integrationTypeId, data } = this.state;

    switch (integrationTypeId) {
      case REST:
        return (
          <RestServiceConfiguration
            data={data}
            integrationTypeId={REST}
            onChange={this.handleServiceDataChange}
            isAsync={false}
          />
        );
      case EMAIL:
        return (
          <EmailServiceConfiguration
            data={data}
            integrationTypeId={EMAIL}
            onChange={this.handleServiceDataChange}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const contentViewStyle = {
      flex: 1,
      minHeight: 0,
    };

    return (
      <DrawerRight
        onClose={this.props.onClose}
        headerView={
          <>
            <NameInput
              appId={this.props.appId}
              id={this.props.serviceId}
              type="service"
              nodeId={this.props.nodeId}
              onNameChange={this.props.onNameChange}
              value={this.props.objectName}
            />
            <DeleteButton
              title={languageMap.deleteService}
              type="service"
              onClick={this.props.deleteNode ? this.deleteNode : null}
            />
          </>
        }
        contentView={
          this.state.isLoading ? (
            <Loader />
          ) : (
            <div style={contentViewStyle}>
              <Tabs>
                <div label={languageMap.settings}>
                  <DropDown
                    value={this.state.integrationTypeId}
                    selection
                    options={INTEGRATIONS}
                    defaultText={languageMap.selectServiceType}
                    onSelect={this.handleTypeChange}
                  />
                  {this.renderView()}
                </div>
                {this.props.hideConnections ? (
                  <div label={languageMap.connectionsLabel}>{languageMap.connectionsHide}</div>
                ) : (
                  <div label={languageMap.connectionsLabel}>
                    <ConnectionsWrapper nodeId={this.props.nodeId} />
                  </div>
                )}
              </Tabs>
            </div>
          )
        }
      />
    );
  }
}

export default withNotifications(ServiceDialog);

import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { openImageModal } from 'pages/Flow/components/ModalContainer/ImageModal';

import './ImageCard.scss';

export default function ImageCard(props) {
  const { titleLabel, titleURL, image, text } = props;

  return (
    <Card className="ImageCard">
      <CardActionArea className="CardActionArea">
        <CardMedia
          className="CardMedia"
          component="img"
          alt="You should see an image"
          image={image}
          onClick={() => openImageModal(image)}
        />
        <CardContent className="CardContent">
          <a href={titleURL} target="_blank" rel="noopener noreferrer">
            <Typography className="ImageCardTitle" gutterBottom>
              {titleLabel}
            </Typography>
          </a>
          {!!text && (
            <iframe title="ImageCardParagraph" className="ImageCardParagraph" srcDoc={text} />
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

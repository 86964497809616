import React, { Component } from 'react';
import Axios from 'axios';
import { produce } from 'immer';
import debounce from 'lodash/debounce';
import { Dropdown } from 'semantic-ui-react';
import get from 'lodash/get';

import withNotifications from 'components/withNotifications';

import Loader from 'components/common/Loader';

import DrawerRight from 'pages/Flow/components/Dialog';
import NameInput from 'pages/Flow/components/NameInput';
import ConnectionsWrapper from 'pages/Flow/containers/ConnectionsWrapper';
import DeleteButton from '../components/DeleteButton';
import Tabs from 'components/common/TabBar';

import Sections from 'components/common/Sections';
import MessageBlocks from 'pages/Flow/components/Message/MessageBlocks';
import VerticalOrderedList from 'pages/Flow/components/Message/VerticalOrderedList';

import DropDown from 'components/common/DropDown';

import './ParentConnectorDialog.scss';
import languageMap from '../../../../../../../../Language/Language';
import CallcenterErrorHandlingMessages from '../../../../../CallcenterErrorHandlingMessages';

class ParentConnectorDialog extends Component {
  state = {
    responseOptions: [],
    responseOptionsByLanguage: {},
    timeoutResponseOptions: [],
    timeoutResponseOptionsByLanguage: {},
    replySuggestions: [],
    replySuggestionsByLanguage: {},
    actions: [],
    isSuggestionsListTemplate: false,
    min_listen_time: 1,
    no_response_timeout: 1.1,
    time_exceeded: 10,
    isLoading: true,
    childConnectorsList: [],
    childConnector: [],
    parentConnectorId: null,
    parentConnectorName: '',
    selectedLanguage: '',
    automaticTranslation: true,
  };

  componentDidMount() {
    this.getIntegrationsData();
    this.getConnectorData();
  }

  onElementChanged = (element) => (value) => {
    this.setState(
      produce((draft) => {
        draft[element] = value;
      }),
      () => {
        this.debouncedStateSync();
      }
    );
  };

  onOptionsByLanguageChange = (element) => (value) => {
    this.setState(
      produce((draft) => {
        draft[element] = {
          ...draft[element],
          [draft.selectedLanguage]: value,
        };
      }),
      () => {
        this.debouncedStateSync(true);
      }
    );
  };

  onParameterChange = (key, value) => {
    this.setState(
      {
        [key]: value,
      },
      () => {
        this.debouncedStateSync();
      }
    );
  };

  onChangeLanguage = (e, { value }) => {
    this.setState({ selectedLanguage: value });
  };

  debouncedStateSync = debounce(async (translate) => {
    const { projectId, objectId } = this.props;
    const {
      responseOptions,
      responseOptionsByLanguage,
      timeoutResponseOptionsByLanguage,
      replySuggestionsByLanguage,
      timeoutResponseOptions,
      replySuggestions,
      actions,
      isSuggestionsListTemplate,
      min_listen_time,
      no_response_timeout,
      time_exceeded,
      automaticTranslation,
    } = this.state;

    const messageProperties = {
      responseOptions,
      responseOptionsByLanguage,
      timeoutResponseOptions,
      timeoutResponseOptionsByLanguage,
      replySuggestions,
      replySuggestionsByLanguage,
      actions,
      isSuggestionsListTemplate,
      min_listen_time,
      no_response_timeout,
      time_exceeded,
    };

    const params = {
      projectId,
      parentConnectorId: objectId,
      messageProperties,
    };

    let URL = `/internal_api/parent_connector?translate=${translate && automaticTranslation}`;

    const response = await Axios.put(URL, params);
    if (automaticTranslation && translate) {
      this.extractSettings(response);
    }
  }, 500);

  deleteNode = () => {
    this.props.onClose();
    this.props.deleteNode(this.props.nodeId);
  };

  getIntegrationsData = () => {
    const { showError, projectId, selectedLanguage } = this.props;
    this.setState({ isLoading: true, selectedLanguage });

    Axios.get('/internal_api/project/integration/data', {
      params: {
        project_id: projectId,
        type_id: 2,
      },
    })
      .then((response) => {
        const data = JSON.parse(response.data.data);
        this.setState(
          produce((draft) => {
            draft.voiceInteraction = data.voice_interaction;
          })
        );
      })
      .catch((e) => {
        showError(e.message);
      });
  };

  getConnectorData = async () => {
    const { projectId, objectId } = this.props;
    const parentConnectorURL = 'internal_api/parent_connector/get_parent_connector';
    try {
      const response = await Axios.get(parentConnectorURL, {
        params: {
          projectId,
          parentConnectorId: objectId,
        },
      });

      const parentConnectorId = response?.data?.parent_connector_id;
      const parentConnectorName = response?.data?.parent_connector_name;

      const childConnectorId = response?.data?.child_connector_id;
      const childConnectorsList = this.getOptions(response?.data?.possbile_child_connectors);

      this.setState({
        childConnectorId,
        childConnectorsList,
        parentConnectorId,
        parentConnectorName,
      });

      this.extractSettings(response);
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      console.error('FAILED - GETTING CHILD CONNECTORS', error);
    }
  };

  extractSettings(response) {
    console.log(response);
    const messageData = get(response, 'data.message_properties');

    this.setState({
      responseOptions: messageData.responseOptions,
      responseOptionsByLanguage: messageData.responseOptionsByLanguage || {},
      timeoutResponseOptionsByLanguage: messageData.timeoutResponseOptionsByLanguage || {},
      timeoutResponseOptions: messageData.timeoutResponseOptions || [],
      replySuggestions: messageData.replySuggestions,
      replySuggestionsByLanguage: messageData.replySuggestionsByLanguage || {},
      actions: messageData.actions,
      isSuggestionsListTemplate: messageData.isSuggestionsListTemplate,
      min_listen_time: messageData.min_listen_time || 1,
      no_response_timeout: messageData.no_response_timeout || 1.1,
      time_exceeded: messageData.time_exceeded || 10,
      automaticTranslation: messageData.automaticTranslation || true,
    });
  }

  updateChildConnectors = async (childConnector) => {
    const { projectId, objectId } = this.props;
    const selectorUpdateURL = 'internal_api/parent_connector';
    await Axios.put(selectorUpdateURL, {
      projectId,
      parentConnectorId: objectId,
      childConnectorId: childConnector[0].value,
    });
    this.props.onNameChange(this.props.nodeId, childConnector[0].label);
  };

  handleConnectorChange = (value) => {
    const { childConnectorsList } = this.state;
    const childConnector = childConnectorsList.filter((child) => child.value === value);
    this.setState({
      childConnector,
      parentConnectorName: childConnector[0].label,
    });
    this.updateChildConnectors(childConnector);
  };

  getOptions = (items) => {
    const list = items.map((item) => {
      return {
        label: item.flow_connector_name,
        value: item.flow_connector_id,
      };
    });

    return list;
  };

  renderChildrenSelector = () => {
    const { childConnectorId, childConnector, childConnectorsList } = this.state;

    return (
      <DropDown
        value={childConnector[0]?.value || childConnectorId}
        options={childConnectorsList}
        defaultText={languageMap.childConnectorListLabel}
        onSelect={this.handleConnectorChange}
      />
    );
  };

  renderMessageBuilder = () => {
    const {
      responseOptionsByLanguage,
      voiceInteraction,
      replySuggestionsByLanguage,
      actions,
      selectedLanguage,
    } = this.state;

    return (
      <>
        <MessageBlocks
          projectId={this.props.projectId}
          options={responseOptionsByLanguage[selectedLanguage]}
          voiceInteraction={voiceInteraction}
          onChange={this.onOptionsByLanguageChange('responseOptionsByLanguage')}
          selectedLanguage={selectedLanguage}
        />
        <div style={{ marginTop: 20 }}>
          <VerticalOrderedList
            title={languageMap.suggestionsLabel}
            elements={replySuggestionsByLanguage[selectedLanguage] || []}
            onChange={this.onOptionsByLanguageChange('replySuggestionsByLanguage')}
            selectedLanguage={selectedLanguage}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <VerticalOrderedList
            title={languageMap.messageActions}
            elements={actions}
            onChange={this.onElementChanged('actions')}
          />
        </div>
      </>
    );
  };

  renderContentView = () => {
    const {
      timeoutResponseOptionsByLanguage,
      min_listen_time,
      time_exceeded,
      no_response_timeout,
      selectedLanguage,
    } = this.state;
    return (
      <div className="content-view">
        <Tabs>
          <div label={languageMap.settings}>
            <Sections>
              {this.renderChildrenSelector()}
              {this.renderMessageBuilder()}
            </Sections>
          </div>
          <div label={languageMap.callCenterLabel}>
            <CallcenterErrorHandlingMessages
              title={languageMap.messageTimeoutOptions}
              description={languageMap.youCanInputDifferentTextResponsesBelow}
              timeoutResponseOptions={timeoutResponseOptionsByLanguage[selectedLanguage] || []}
              minListenTime={min_listen_time}
              noResponseTimeout={no_response_timeout}
              timeExceeded={time_exceeded}
              onParameterChange={this.onParameterChange}
              selectedLanguage={selectedLanguage}
              onMessageOptionsChange={this.onOptionsByLanguageChange(
                'timeoutResponseOptionsByLanguage'
              )}
            />
          </div>
          <div label={languageMap.connectionsLabel}>
            <ConnectionsWrapper nodeId={this.props.nodeId} />
          </div>
        </Tabs>
      </div>
    );
  };

  render() {
    const { isLoading, childConnector, selectedLanguage } = this.state;
    const { onClose, appId, objectId, nodeId, onNameChange, objectName, languageOptions } =
      this.props;

    return (
      <DrawerRight
        className="ParentConnectorDialog"
        onClose={onClose}
        headerView={
          <>
            <NameInput
              appId={appId}
              id={objectId}
              type="parent_connector"
              nodeId={nodeId}
              onNameChange={onNameChange}
              value={objectName}
              childConnector={childConnector}
            />
            {languageOptions.length > 1 && (
              <Dropdown
                className="languageDropdown"
                selection
                value={selectedLanguage}
                options={languageOptions}
                onChange={this.onChangeLanguage}
              />
            )}
            <DeleteButton
              title={languageMap.deleteParentConnector}
              type="parent-connector"
              onClick={this.deleteNode}
            />
          </>
        }
        contentView={isLoading ? <Loader /> : this.renderContentView()}
      />
    );
  }
}

export default withNotifications(ParentConnectorDialog);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useResolvedPath, useNavigate } from 'react-router-dom';

import {
  selectCurrentConversation,
  selectHistoryConversations,
  selectInboxFolder,
  selectIsMobile,
} from 'store/crm/selectors';

import './LatestConversationsCard.scss';
import { integrationTitle } from '../../../../../../ProjectDetails/components/Integrations/Integrations';
import ExternalConversationCard from '../../../../../../../components/Conversation/components/ExternalConversationCard/ExternalConversationCard';
import getElapsedTime from '../../../../../../../utils/getElapsedTime';
import { processMessage } from '../../../../../../../utils/crm';
import {
  getChatHistory,
  removeFromExternalAllUnread,
  removeFromInternalAllUnread,
  removeFromUnassignedUnread,
  removeFromYouUnread,
  setConversationReadStatus,
  setCurrentConversation,
} from '../../../../../../../store/crm/actions';
import languageMap from '../../../../../../../Language/Language';
import { WhiteButton } from '../../../../../../../components/common/Button/Buttons';
import { removeFromUnread } from '../../../../../../../components/Conversation/Conversation';

const LatestConversationsCard = (props) => {
  const {
    currentConversation,
    conversations,
    setChatHistory,
    setCurrentConversation,
    returnBackToConversationScreen,
    setConversationReadStatus,
    isMobile,
  } = props;
  const navigate = useNavigate();
  const { id: projectId } = useParams();
  const [hasMoreClicked, setHasMoreClicked] = useState(false);
  const url = useResolvedPath('').pathname;
  let displayedConversations = conversations;
  const handleClick = (conversation, conversation_id, user_id) => async () => {
    await setChatHistory({ conversation_id, user_id, project_id: projectId });

    setCurrentConversation(conversation);
    setConversationReadStatus({ conversation_id, user_id, project_id: projectId });
    removeFromUnread(conversation_id, props);
    const clean = url.substring(0, url.indexOf('inbox') + 5);
    const conversationPath = `${clean}/${user_id}_${conversation_id}`;
    navigate(conversationPath);
    if (isMobile) {
      returnBackToConversationScreen();
    }
  };
  if (conversations?.length === 0) {
    return <div className="no-conversations">{languageMap.noConversations}</div>;
  }
  if (conversations?.length > 3 && !hasMoreClicked) {
    displayedConversations = displayedConversations.slice(0, 3);
  }
  return (
    <>
      {displayedConversations?.map((conversation, index) => {
        const {
          channel_id,
          department_name,
          user_display_name,
          last_message,
          conversation_id,
          user_id,
          is_conversation_read,
        } = conversation;
        const { parsedMessage: lastMessage, timestamp } = processMessage(last_message) || {};
        return (
          <div
            key={conversation_id}
            className="LatestConversations_item"
            onClick={handleClick(conversation, conversation_id, user_id)}
          >
            <ExternalConversationCard
              elapsedTime={getElapsedTime(timestamp)}
              isConversationRead={is_conversation_read}
              lastMessage={lastMessage}
              departmentName={department_name}
              channelName={integrationTitle[channel_id]}
              userDisplayName={user_display_name}
              isActive={currentConversation.conversation_id === conversation_id}
            />
          </div>
        );
      })}
      {!hasMoreClicked && conversations.length > 3 && (
        <div style={{ marginTop: 10, marginBottom: 10, textAlign: 'center' }}>
          <WhiteButton text={languageMap.loadMore} onClick={() => setHasMoreClicked(true)} />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  currentConversation: selectCurrentConversation(state),
  conversations: selectHistoryConversations(state),
  isMobile: selectIsMobile(state),
  inboxFolder: selectInboxFolder(state),
});
const mapDispatchToProps = {
  setCurrentConversation,
  setChatHistory: getChatHistory,
  setConversationReadStatus,
  removeFromYouUnread,
  removeFromUnassignedUnread,
  removeFromExternalAllUnread,
  removeFromInternalAllUnread,
};
export default connect(mapStateToProps, mapDispatchToProps)(LatestConversationsCard);

import React, { Component } from 'react';
import Axios from 'axios';
import Delete from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import withNotifications from 'components/withNotifications';
import DrawerRight from 'pages/Flow/components/Dialog';
import Loader from 'components/common/Loader';
import languageMap from 'Language/Language';

import { connect } from 'react-redux';
import extractLanguageInfo from '../../../../../../utils/extractLanguageInfo';
import MessageBlocks from '../../../../../Flow/components/Message/MessageBlocks';
import { Dropdown } from 'semantic-ui-react';
import { AddButton } from '../../../../../../components/common/Button';
import {
  HTML_BLOCK,
  SIMPLE_TEXT_BLOCK,
} from '../../../../../Flow/components/Message/MessageBlocks/blocks-config';
import getProactiveMessages from '../WebchatConfigurationDialog/getProactiveMessages';
import updateMessage from '../../../../../../services/updateMessage';
import OptionCheckbox from '../../../../../Flow/components/Message/MessageBlocks/components/OptionCheckbox';
import HelperTooltip from '../../../../../../components/HelperTooltip/HelperTooltip';
import { FlexRow } from '../../../../../../components/Utterances/Misc';

const initialState = {
  isLoading: true,
  header_message_id: null,
  footer_message_id: null,
  header_message_properties: null,
  footer_message_properties: null,
  automaticTranslation: true,
  selectedLanguage: '',
  isLoadingRequest: false,
};
const BLOCK_TYPES_EMAIL = [
  {
    type: SIMPLE_TEXT_BLOCK,
    text: languageMap.text,
  },
  {
    type: HTML_BLOCK,
    text: languageMap.html,
  },
];
const OPTIONS_KEY = 'responseOptionsByLanguage';

class EmailConfigurationDialog extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  async componentDidMount() {
    const { projectId } = this.props;
    await Axios.get('/internal_api/project/integration/data', {
      params: {
        project_id: projectId,
        type_id: 10,
      },
    })
      .then(async (response) => {
        if (response.data.status === 'ok') {
          let data = JSON.parse(response.data.data);
          console.log(data);
          const { header_message_id, footer_message_id, automaticTranslation } = data;

          this.setState({
            header_message_id: header_message_id || '',
            footer_message_id: footer_message_id || '',
            automaticTranslation: automaticTranslation ?? true,
            selectedLanguage: this.props.selectedLanguage,
          });
          let res = [];
          if (header_message_id) {
            res = await getProactiveMessages([{ message_id: header_message_id }], projectId);
            this.setState({
              header_message_properties: res[0]?.message_properties,
            });
          }
          if (footer_message_id) {
            res = await getProactiveMessages([{ message_id: footer_message_id }], projectId);
            this.setState({
              footer_message_properties: res[0]?.message_properties,
            });
          }
          this.setState({ isLoading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isLoading: false });
        this.props.close();
      });
  }

  changeIsLoadingRequest = () => {
    this.setState({ isLoadingRequest: !this.state.isLoadingRequest });
  };

  onMessagesSave = (key) => async (value) => {
    const {
      [key + '_id']: message_id,
      [key + '_properties']: message_properties,
      selectedLanguage,
      automaticTranslation,
    } = this.state;
    const { projectId } = this.props;
    this.changeIsLoadingRequest(true);
    const newMessageProperties = {
      ...message_properties,
      [OPTIONS_KEY]: {
        ...message_properties[OPTIONS_KEY],
        [selectedLanguage]: value,
      },
    };
    const response = await updateMessage(
      newMessageProperties,
      message_id,
      projectId,
      automaticTranslation
    );
    this.setState({
      [key + '_properties']: response?.data?.message_details?.message_properties,
    });
    this.changeIsLoadingRequest(false);
  };
  onTranslationChange = (value) => {
    this.setState(
      {
        automaticTranslation: value,
      },
      () => this.handleSyncWebchatSettings('automaticTranslation')
    );
  };

  handleSyncWebchatSettings = (setting) => {
    const { showError, projectId } = this.props;

    return Axios.post('/internal_api/project/integration/update', {
      projectId,
      typeId: 10,
      column: 'data',
      value: JSON.stringify({
        [setting]: this.state[setting],
      }),
    }).catch((error) => showError(error.message));
  };

  addMessage = (messageKey) => async () => {
    const { isLoadingRequest } = this.state;
    const { projectId } = this.props;
    if (isLoadingRequest) {
      return;
    }
    this.changeIsLoadingRequest(true);
    let response;
    try {
      response = await Axios.post('/internal_api/message', {
        appId: 0,
        project_id: projectId,
      });
    } catch (err) {
      console.log(err);
      this.changeIsLoadingRequest(false);
      return;
    }
    this.setState(
      {
        [messageKey + '_id']: response.data.message_id,
        [messageKey + '_properties']: JSON.parse(response.data.message_properties),
      },
      () => this.handleSyncWebchatSettings(messageKey + '_id')
    );
    this.changeIsLoadingRequest(false);
  };

  onChangeLanguage = (e, { value }) => {
    this.setState({ selectedLanguage: value });
  };

  deleteMessage = (messageKey) => async () => {
    const { isLoadingRequest, [messageKey + '_id']: message_id } = this.state;

    if (isLoadingRequest) {
      return;
    }
    this.changeIsLoadingRequest(true);
    await Axios.delete('/internal_api/message', { data: { id: message_id } });
    this.setState({ [messageKey + '_id']: null });
    this.setState({ [messageKey + '_properties']: null }, () =>
      this.handleSyncWebchatSettings(messageKey + '_id')
    );

    this.changeIsLoadingRequest(false);
  };

  render() {
    const {
      selectedLanguage,
      isLoading,
      isLoadingRequest,
      header_message_properties,
      footer_message_properties,
      automaticTranslation,
    } = this.state;
    const { close, projectId, languageOptions } = this.props;
    const currentHeader = header_message_properties?.responseOptionsByLanguage?.[selectedLanguage];
    const currentFooter = footer_message_properties?.responseOptionsByLanguage?.[selectedLanguage];
    return (
      <DrawerRight
        onClose={close}
        isLoading={isLoadingRequest}
        headerView={<div className="WebchatConfigurationDialog_Header">{languageMap.email}</div>}
        contentView={
          isLoading ? (
            <Loader />
          ) : (
            <div style={{ padding: 15 }}>
              <h3 style={{ marginLeft: 10, color: '#56595f' }}>
                {languageMap.informationWindowText}
              </h3>

              <div style={{ marginLeft: 10, marginTop: 10, fontWeight: 600, color: '#56595f' }}>
                {languageMap.defaultDisplayLanguage + ':'}
                {languageOptions.length > 1 && (
                  <Dropdown
                    className="languageDropdown"
                    selection
                    value={selectedLanguage}
                    options={languageOptions}
                    onChange={this.onChangeLanguage}
                  />
                )}
              </div>
              <FlexRow>
                <OptionCheckbox
                  style={{ marginRight: 0 }}
                  checked={automaticTranslation}
                  onChange={() => this.onTranslationChange(!automaticTranslation)}
                  title={languageMap.automaticTranslation}
                />
                <HelperTooltip
                  style={{ marginLeft: 10, height: 25, width: 25 }}
                  title={languageMap.helperTextAutomaticTranslation}
                />
              </FlexRow>

              <div
                key="intro"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 10,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 600,
                    color: '#56595f',
                  }}
                >
                  {languageMap.header}

                  {!currentHeader && (
                    <AddButton
                      text={languageMap.addNewMessage}
                      icon="add"
                      style={{ marginLeft: 10, minWidth: 40, padding: 8 }}
                      onClick={this.addMessage('header_message')}
                    />
                  )}
                  {currentHeader && (
                    <IconButton
                      onClick={this.deleteMessage('header_message')}
                      size="small"
                      style={{ marginLeft: 10 }}
                    >
                      <Delete style={{ width: '30px', height: '30px' }} />
                    </IconButton>
                  )}
                </div>
                {currentHeader && (
                  <MessageBlocks
                    hideTitle={true}
                    messageId={''}
                    projectId={projectId}
                    options={currentHeader}
                    voiceInteraction={false}
                    onChange={this.onMessagesSave('header_message')}
                    selectedLanguage={selectedLanguage}
                    loadingChange={this.changeIsLoadingRequest}
                    blockTypes={BLOCK_TYPES_EMAIL}
                  />
                )}
              </div>
              <div style={{ marginTop: 25, marginBottom: 10, borderTop: 'solid 1px lightgrey' }} />

              <div
                key="outro"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 10,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 600,
                    color: '#56595f',
                  }}
                >
                  {languageMap.footer}
                  {!currentFooter && (
                    <AddButton
                      text={languageMap.addNewMessage}
                      icon="add"
                      style={{ marginLeft: 10, minWidth: 40, padding: 8 }}
                      onClick={this.addMessage('footer_message')}
                    />
                  )}
                  {currentFooter && (
                    <IconButton
                      onClick={this.deleteMessage('footer_message')}
                      size="small"
                      style={{ marginLeft: 10 }}
                    >
                      <Delete style={{ width: '30px', height: '30px' }} />
                    </IconButton>
                  )}
                </div>
                {currentFooter && (
                  <MessageBlocks
                    hideTitle={true}
                    messageId={''}
                    projectId={projectId}
                    options={currentFooter}
                    voiceInteraction={false}
                    showAnimatePlaceholder={false}
                    onChange={this.onMessagesSave('footer_message')}
                    selectedLanguage={selectedLanguage}
                    loadingChange={this.changeIsLoadingRequest}
                    blockTypes={BLOCK_TYPES_EMAIL}
                  />
                )}
              </div>
            </div>
          )
        }
      />
    );
  }
}

export default connect(extractLanguageInfo)(withNotifications(EmailConfigurationDialog));

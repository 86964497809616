import React, { Component } from 'react';
import { v4 } from 'uuid';
import capitalize from 'utils/capitalize';
import './ResponseSuggestions.scss';
export default class ResponseSuggestions extends Component {
  render() {
    let suggestions = undefined;

    if (this.props.suggestions !== undefined) {
      suggestions = this.props.suggestions.map((suggestion, index) => {
        return (
          <div key={index} className="ReplySuggestionElement regular-text">
            {capitalize(suggestion)}
          </div>
        );
      });
    }

    return (
      <div key={v4()} className="ReplySuggestionContainer">
        {suggestions}
      </div>
    );
  }
}

import React from 'react';
import Axios from 'axios';

import ModalC from 'pages/Flow/components/ModalContainer';
import InputText from 'components/common/input/InputText';
import { AddButton } from 'components/common/Button/Buttons';
import './FlowSettingsDialog.scss';
import languageMap from '../../../../Language/Language';
import RegexList from '../../../../components/RegexList/RegexList';
import clsx from 'clsx';

export default class FlowSettingsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectId: this.props.projectId,
      flowId: this.props.flowId,
      flowName: this.props.flowName,
      flowNameOld: this.props.flowName,
      qnaRegex: this.props.qnaRegex,
    };
  }

  onValueChanged = (key, value) => {
    this.setState({ [key]: value });
  };

  onUpdate = () => {
    const { onFlowChanged, flowId, projectId, isQna } = this.props;
    const data = {
      flow_name: this.state.flowName,
    };
    if (isQna) {
      data.url_regexes = this.state.qnaRegex;
    }
    Axios.post('/internal_api/project/' + projectId + '/flow/' + flowId, data).then(() => {
      onFlowChanged();
    });
  };

  handleRegexChange = (qnaRegex) => {
    this.setState({ qnaRegex });
  };

  render() {
    const { flowName } = this.state;
    const { onHide, isQna } = this.props;
    let content;

    content = (
      <div className="FlowSettingsDialog_DialogContainer_ContentContainer">
        <label className="FlowSettingsDialog_DialogContainer_ContentContainer_InputLabel">
          {isQna ? languageMap.qnaName : languageMap.flowName}
        </label>
        <InputText
          value={flowName}
          onValueChanged={this.onValueChanged}
          placeholder={isQna ? languageMap.qnaName : languageMap.flowName}
          name="flowName"
        />

        {isQna && <RegexList values={this.state.qnaRegex} onChange={this.handleRegexChange} />}

        <div className="FlowSettingsDialog_DialogContainer_ContentContainer_ButtonsContainer">
          <AddButton onClick={this.onUpdate} text="Update" />
          <AddButton onClick={onHide} text="Cancel" />
        </div>
      </div>
    );

    return (
      <ModalC onHide={onHide}>
        <div className={clsx('FlowSettingsDialog', isQna && 'QnASettings')}>
          <div className="FlowSettingsDialog_BackgroundCover" onClick={onHide} />
          <div className="FlowSettingsDialog_DialogContainer">
            <span onClick={onHide} className="material-icons">
              close
            </span>
            {content}
          </div>
        </div>
      </ModalC>
    );
  }
}

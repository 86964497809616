import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import languageMap from 'Language/Language';

export default (props) => {
  const { ttsVoices, languageOptions, onChange, voices } = props;
  const ttsVoicesByLanguage = {};
  ttsVoices.forEach((voice) => {
    const lang = voice.language.split('-')[0];
    if (lang in ttsVoicesByLanguage) {
      ttsVoicesByLanguage[lang].push({
        text: `(${lang}) ${voice.display_name} - ${voice.engine}`,
        value: voice.voice_id,
      });
    } else {
      ttsVoicesByLanguage[lang] = [
        {
          text: `(${lang}) ${voice.display_name} - ${voice.engine}`,
          value: voice.voice_id,
        },
      ];
    }
  });
  const onSelect = (event, data) => {
    const voice = ttsVoices.find((voice) => voice.voice_id === data.value);
    const lang = voice.language.split('-')[0];
    onChange({
      voices: {
        ...voices,
        [lang]: voice,
      },
    });
  };

  return (
    <div className="IntegrationVoicesContainer">
      <div className="WebchatConfigurationDialog_Content_Options_ColumnRight_OptionContainer_OptionTitle">
        {languageMap.voices}
      </div>
      {languageOptions.map((language) => (
        <div className="IntegrationVoicesRow" key={language.value}>
          {language.text}
          <Dropdown
            style={{ marginLeft: 12 }}
            selection
            value={voices[language.value]?.voice_id}
            options={ttsVoicesByLanguage[language.value]}
            className="CompactDropdown"
            onChange={onSelect}
          />
        </div>
      ))}
    </div>
  );
};

import { initializeApp } from 'firebase/app';

import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import store from '../../store';
import { selectCurrentConversation, selectCurrentUser } from '../../store/crm/selectors';
import languageMap from '../../Language/Language';
import getAssetPath from '../../utils/getAssetPath';

// Firebase Config values imported from .env file
const firebaseConfig = {
  apiKey: 'AIzaSyD7tSmPS_AM2LdHfZAyB77et-6S3QEglKM',
  authDomain: 'wisevoice-notifications.firebaseapp.com',
  projectId: 'wisevoice-notifications',
  storageBucket: 'wisevoice-notifications.appspot.com',
  messagingSenderId: '455792431659',
  appId: '1:455792431659:web:bd01921a6d706d03c8d06e',
  measurementId: 'G-B1Z73XGHHP',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
let messaging;
let latestNotificationId = '';
export const getMessagingObj = async () => {
  const supported = await isSupported();
  if (!supported || typeof window === 'undefined') return null;
  return getMessaging(app);
};

const getSubscription = () => {
  if (!navigator) return;
  const subscriptions = navigator.serviceWorker.getRegistrations().then((r) => {
    return r.map((sw) => {
      if (!(sw.active && sw.active.scriptURL.includes('firebase-messaging')))
        return Promise.resolve();
      return sw.pushManager.getSubscription();
    });
  });

  return subscriptions.then((arr) => (arr ? arr[0] : Promise.resolve(null)));
};

const delayPromise = (cb, delay) => {
  return new Promise((resolve) => {
    setTimeout(async () => {
      const resp = await cb();
      resolve(resp);
    }, delay);
  });
};
export const requestPermission = async (userId, language) => {
  let registration;
  messaging = await getMessagingObj(app);
  if (!messaging) {
    return;
  }
  if (!('serviceWorker' in navigator)) {
    return;
  }
  // requesting permission using Notification API
  const permission = await Notification.requestPermission();

  if (permission === 'granted') {
    let token;
    registration = await navigator.serviceWorker
      .register(getAssetPath(`firebase-messaging-sw.js?userId=${userId}&language=${language}`))
      .catch((err) => {
        console.error('Error registering service worker', err);
      });
    // We need to delay until worker is active
    // https://github.com/firebase/firebase-js-sdk/issues/7693
    token = await delayPromise(async () => {
      return await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
        serviceWorkerRegistration: registration,
      }).catch(async (err) => {
        console.error(err);
        token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
          serviceWorkerRegistration: registration,
        }).catch(async (err) => {
          console.error('Second time: ', err);
        });
        return token;
      });
    }, 200);

    console.log('Token generated :', token);
    return token;
  } else if (permission === 'denied') {
    // notifications are blocked
    console.warn('User denied notifications');
    return;
  }
  if ('permissions' in navigator) {
    navigator.permissions.query({ name: 'notifications' }).then(function (notificationPerm) {
      notificationPerm.onchange = function () {
        console.log(
          'User decided to change his seettings. New permission: ' + notificationPerm.state
        );
        if (notificationPerm.state === 'denied') {
          messageUnsubscribe();
        }
      };
    });
  }
};

export const messageUnsubscribe = () => {
  return getSubscription().then((pushSubscription) => {
    return pushSubscription?.unsubscribe?.();
  });
};

export const subscribeToFirebase = () => {
  onMessage(messaging, (payload) => {
    if (document.hidden) {
      // foreground means one active tab
      return;
    }
    if (payload?.data?.notificationId !== latestNotificationId) {
      latestNotificationId = payload?.data?.notificationId;
    } else {
      return;
    }
    const state = store.getState();
    const currentConversationId = selectCurrentConversation(state)?.conversation_id;
    const currentUserId = selectCurrentUser(state)?.user_id;
    const notificationConversationId = Number(payload?.data?.conversationId);
    const assignee = payload?.data.assignee;
    const senderId = payload?.data.senderId;
    const userId = payload?.data.userId;
    const projectId = payload?.data.projectId;
    console.log('foreground message', payload);
    console.log('currentConversationId', currentConversationId);
    console.log('currentUserId', currentUserId);
    const type = payload?.data?.type;
    if (type === 'message' && Number(senderId) === Number(currentUserId)) {
      return;
    }
    if (Number(currentConversationId) === notificationConversationId) {
      return;
    }
    let title, body;
    if (type === 'assign') {
      if (Number(currentUserId) === Number(assignee)) {
        title = languageMap.notificationAssignedConversationToYouTitle;
        body = languageMap.notificationAssignedConversationToYouBody;
      } else {
        title = languageMap.notificationUnAssignedConversationTitle;
        body = languageMap.notificationUnAssignedConversationBody;
      }
    } else {
      title = languageMap.notificationNewMessageTitle;
      body = languageMap.notificationNewMessageBody;
    }
    const notification = new Notification(title, {
      body,
      icon: getAssetPath('favicon.ico'),
    });
    notification.onclick = () => {
      window.location.href =
        '/project/' + projectId + '/crm/inbox' + `/${userId}_${notificationConversationId}`;
    };
  });
};

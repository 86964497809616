import React, { useCallback, useEffect, useState } from 'react';
import Axios from 'axios';
import { produce } from 'immer';
import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';

import { ANNOTATION_CLUSTERS_ENDPOINT } from 'utils/constants';
import Loader from 'components/common/Loader';
import Card from '../../components/common/Card';
import languageMap from '../../Language/Language';

const Clusters = (props) => {
  const { projectId, contentType } = props;
  const [clusters, setClusters] = useState({});
  const [loading, setLoading] = useState(false);

  const fetchClusters = useCallback(() => {
    setLoading(true);
    Axios.get(ANNOTATION_CLUSTERS_ENDPOINT, {
      params: {
        project_id: projectId,
        content_type: contentType,
      },
    })
      .then(({ data }) => {
        setClusters(data);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    fetchClusters();
  }, [fetchClusters]);

  const setShowAll = (cluster_id, show_all) => {
    const x = produce(clusters, (draft) => {
      draft[cluster_id].show_all = show_all;
    });
    setClusters(x);
  };

  let cards = Object.keys(clusters).map((key) => {
    let cluster = clusters[key];
    let utterances = _.cloneDeep(cluster.annotations);
    let showButtonText = 'show less';
    let title = cluster.theme || utterances[0];
    if (!cluster.theme) {
      utterances.shift();
    }

    if (!cluster.show_all) {
      showButtonText = 'show all ' + utterances.length;
      utterances = utterances.slice(cluster.theme ? 0 : 1, cluster.theme ? 2 : 3);
    }

    return (
      <Card key={key} className="AnnotationCard AnnotationCardCluster">
        <div className="TextContainer">
          <div className="Title"> {title}</div>
          {utterances.map((utterance, index) => {
            return <div key={utterance + index}>{utterance}</div>;
          })}
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setShowAll(key, !cluster.show_all);
            }}
          >
            {showButtonText}
          </a>
        </div>
      </Card>
    );
  });

  return (
    <>
      {loading && <Loader className="Annotations_Loader" />}
      <div className="Annotations_ScrollContainer clusters_container">
        {isEmpty(Object.keys(clusters)) && !loading && (
          <div>{languageMap.noResultsForCurrentSearchCriteria}</div>
        )}
        {cards}
      </div>
    </>
  );
};

export default Clusters;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Axios from 'axios';

import ContextMenu from 'components/ContextMenu';
import ModalC from 'pages/Flow/components/ModalContainer';
import { downloadFileFromUrl } from 'utils/utils';
import './FlowListElement.scss';
import languageMap from 'Language/Language';
import store from '../../../../store';
import { setLoading } from '../../../../store/global/actions';

export class FlowListElement extends Component {
  constructor(props) {
    super(props);
    const data = [
      {
        label: props.isQna ? languageMap.edit : languageMap.rename,
        returnValue: {
          type: 'rename',
        },
      },
      {
        label: languageMap.delete,
        returnValue: {
          type: 'delete',
        },
      },
    ];
    if (!props.isQna) {
      data.unshift({
        label: languageMap.download,
        returnValue: {
          type: 'download',
        },
      });
    }
    this.state = {
      showFlowMenu: false,
      flowMenuData: data,
      qnaRegex: [],
    };
  }

  downloadFlow = () => {
    const url =
      '/internal_api/project/' +
      this.props.projectId +
      '/flow/' +
      this.props.flowId +
      '/download_app';
    const contentType = 'application/json';
    const fileName = `flow_${this.props.projectId}_${this.props.flowId}`;
    downloadFileFromUrl(url, contentType, fileName);
  };

  deleteFlow = () => {
    store.dispatch(setLoading(true));
    Axios.post(
      '/internal_api/project/' + this.props.projectId + '/flow/' + this.props.flowId + '/delete'
    )
      .then(() => {
        this.props.onFlowChanged();
        store.dispatch(setLoading(false));
      })
      .catch(() => store.dispatch(setLoading(false)));
  };

  handleSelection = (selection) => {
    this.setState({ showFlowMenu: false });
    if (selection.type === 'rename') {
      this.props.editFlow(this.props.flowId, this.props.flowTitle, this.props.qnaRegex);
    } else if (selection.type === 'download') {
      this.downloadFlow(this);
    } else if (selection.type === 'delete') {
      this.deleteFlow(this);
    }
  };

  toggleMenu = (e) => {
    this.setState({
      showFlowMenu: !this.state.showFlowMenu,
      mouse_x: e.pageX,
      mouse_y: e.pageY,
    });
  };

  render() {
    const { mouse_x, mouse_y, flowMenuData } = this.state;
    const { flowId, pathName, flowTitle } = this.props;
    let showFlowMenu;

    if (this.state.showFlowMenu)
      showFlowMenu = (
        <ModalC
          onHide={() => {
            this.setState({ showFlowMenu: false });
          }}
        >
          <ContextMenu
            left={mouse_x}
            top={mouse_y}
            options={flowMenuData}
            onSelect={this.handleSelection}
          />
        </ModalC>
      );

    return (
      <div className="FlowListElement">
        <Link
          key={flowId}
          to={{
            pathname: pathName,
          }}
        >
          <div className="FlowListElement_Subcontainer">
            <div className="FlowListElement_Subcontainer_Icon" />
            <div className="FlowListElement_Subcontainer_FlowTitle">
              <span>{flowTitle}</span>
            </div>
          </div>
        </Link>

        <div className="FlowListElement_Subcontainer">
          <div className="FlowListElement_Subcontainer_FlowMenuButton" onClick={this.toggleMenu}>
            <div className="FlowListElement_Subcontainer_FlowMenuButton_ButtonDot" />
            <div className="FlowListElement_Subcontainer_FlowMenuButton_ButtonDot" />
            <div className="FlowListElement_Subcontainer_FlowMenuButton_ButtonDot" />
          </div>
        </div>
        {showFlowMenu}
      </div>
    );
  }
}

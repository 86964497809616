import React, { Component } from 'react';
import { ThemeContext } from '../../utils/Theme';

import languageMap from 'Language/Language';
import { WhiteButton } from '../../../../components/common/Button/Buttons';
import LoadingOverlay from '../../../../components/common/LoadingOverlay/LoadingOverlay';
import ModalC from '../ModalContainer';

const dialog_style = {
  width: 800,
  height: '100%',
  backgroundColor: 'white',
  position: 'absolute',
  // float: 'right',
  top: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
};
const cover_style = {
  position: 'fixed',
  top: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'black',
  opacity: 0.85,
};

const button_style = {
  margin: 18,
};

export default class DrawerRight extends Component {
  static contextType = ThemeContext;

  render() {
    const theme = this.context;
    const header_style = {
      backgroundColor: theme.main,
      width: '100%',
      height: 62,
      display: 'flex',
      flexFlow: 'row nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 0,
    };

    const { isLoading, onClose, className } = this.props;
    return (
      <ModalC onWheel={(e) => e.stopPropagation()} onHide={onClose}>
        <div style={cover_style} onClick={onClose} />
        <div className={className} style={dialog_style}>
          <div style={header_style}>
            <LoadingOverlay isLoading={isLoading} style={{ color: theme.main }} />
            <WhiteButton
              style={button_style}
              onClick={this.props.onClose}
              text={languageMap.close}
            />
            {this.props.headerView}
          </div>

          {this.props.contentView}
        </div>
      </ModalC>
    );
  }
}

import React from 'react';
import { connect } from 'react-redux';

import { isLoading } from 'store/global/selectors';

import { Dimmer, Loader } from 'semantic-ui-react';

function LoadingDimmer(props) {
  const style = {
    height: '100vh',
    width: '100%',
    left: 0,
    top: 0,
    overflowY: 'hidden',
    position: 'fixed',
    zIndex: 99999,
  };

  if (props.loading) {
    return (
      <div style={style}>
        <Dimmer active>
          <Loader content="Loading" />
        </Dimmer>
      </div>
    );
  }

  return '';
}

const mapStateToProps = (state) => ({ loading: isLoading(state) });
export default connect(mapStateToProps, {})(LoadingDimmer);

import React, { Component } from 'react';
import Axios from 'axios';
import { produce } from 'immer';
import languageMap from 'Language/Language';

import DrawerRight from 'pages/Flow/components/Dialog';
import InputText from 'components/common/input/InputText';
import { AddButton } from 'components/common/Button/Buttons';
import { authenticate } from 'components/Auth/auth';
import withNotifications from 'components/withNotifications';
import './MSTeamsConfigurationDialog.scss';
import { TOKEN_KEY_PREFIX } from '../../../../../../utils/constants';

class MSTeamsConfigurationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tenantId: '',
    };

    this.isLoaded = false;
  }

  componentDidMount = async () => {
    const projectId = this.props.projectId;

    if (localStorage[TOKEN_KEY_PREFIX + projectId]) {
      this.getIntegrationData(projectId);
    } else {
      await authenticate(projectId);
      this.getIntegrationData(projectId);
    }
  };

  getIntegrationData(projectId) {
    Axios.get('/internal_api/project/integration/data', {
      params: {
        project_id: projectId,
        type_id: 7,
      },
    })
      .then((response) => {
        if (response.data.status === 'ok') {
          let data = JSON.parse(response.data.data);

          this.setState(
            produce((draft) => {
              draft.tenantId = data ? data.tenant_id : '';
            })
          );

          this.isLoaded = true;
        }
      })
      .finally(() => {
        if (!this.isLoaded) {
          alert('Could not load integration data');
          this.props.close();
        }
      });
  }

  handleTenantIdChange = (key, value) => {
    this.setState({ [key]: value });
  };

  save = () => {
    const { projectId } = this.props;
    Axios.post(
      `https://${process.env.REACT_APP_DOMAIN_NAME}/api/project/integration/checkTenantId`,
      {
        projectId,
        tenantId: this.state.tenantId,
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage[TOKEN_KEY_PREFIX + projectId],
        },
      }
    )
      .then((response) => {
        if (response.data.success) {
          this.props.showSuccess(response.data.message);
        } else {
          this.props.showError(response.data.message);
        }
        this.props.close();
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  render() {
    const { tenantId } = this.state;
    const { close } = this.props;

    return (
      <DrawerRight
        onClose={close}
        headerView={
          <div className="MSTeamsConfigurationDialog_header">{languageMap.microsoftTeams}</div>
        }
        contentView={
          <div className="MSTeamsConfigurationDialog">
            <InputText
              value={tenantId}
              onValueChanged={this.handleTenantIdChange}
              placeholder="Tenant ID"
              name="tenantId"
            />
            <AddButton
              onClick={this.save}
              text={languageMap.verifyAndSave}
              style={{ marginLeft: 8 }}
            />
          </div>
        }
      />
    );
  }
}

export default withNotifications(MSTeamsConfigurationDialog);

import React, { useState } from 'react';
import Axios from 'axios';

import DrawerRight from 'pages/Flow/components/Dialog';
import InputFieldsForModels from '../InputFieldsForModels';
import languageMap from 'Language/Language';
import './OCRConfigurationDialog.scss';
import useComponentDidMount from '../../../../../../utils/useComponentDidMount';
import Loader from 'components/common/Loader';

let inputTitles = [
  { label: languageMap.modelName, value: '', prop: 'model_name' },
  { label: languageMap.apiKey, value: '', prop: 'key' },
  { label: languageMap.endpoint, value: '', prop: 'endpoint' },
];

let modelOptions = [{ label: 'Azure', value: 'azure' }];
const OCR_KEY = 'national_card_processor_model';

const OCRConfigurationDialog = (props) => {
  const { close, projectId } = props;
  const [isLoadingInit, setIsLoadingInit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [ocrData, setOcrData] = useState({});

  useComponentDidMount(() => {
    Axios.get('/internal_api/project/integration/data', {
      params: {
        project_id: projectId,
        type_id: 15,
      },
    })
      .then((resp) => {
        setIsLoadingInit(false);
        let data = JSON.parse(resp.data.data || '{}');
        setOcrData(data[OCR_KEY]?.[0] || {});
      })
      .catch((error) => {
        setIsLoadingInit(false);
        console.log(error.message);
      });
  });

  const handleValuesChange = (data) => {
    setOcrData(data);
  };
  const handleSave = (data) => {
    handleSyncIntegrationSettings(OCR_KEY, [data ? data : ocrData]);
  };

  const handleSyncIntegrationSettings = (setting, value) => {
    setIsLoading(true);
    return Axios.post('/internal_api/project/integration/update', {
      projectId,
      typeId: 15,
      column: 'data',
      value: JSON.stringify({
        [setting]: value,
      }),
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.message);
        setIsLoading(false);
      });
  };
  const provider = ocrData.provider;
  const otherValues = { ...ocrData };
  delete otherValues.provider;
  return (
    <DrawerRight
      onClose={close}
      headerView={<div className="MSTeamsConfigurationDialog_header">{languageMap.ocr}</div>}
      isLoading={isLoading}
      contentView={
        isLoadingInit ? (
          <Loader />
        ) : (
          <div className="OCRConfigurationDialog">
            <InputFieldsForModels
              options={modelOptions}
              inputTitles={inputTitles}
              inputValues={otherValues}
              selectedProvider={provider}
              handleValuesChange={handleValuesChange}
              handleSave={handleSave}
            />
          </div>
        )
      }
    />
  );
};

export default OCRConfigurationDialog;

import React from 'react';
import ReactDOM from 'react-dom';
import _ from 'lodash';
const modalRoot = document.getElementById('modal-root');
// const modalBackground = document.getElementById('modal-background');

export default class ModalC extends React.Component {
  constructor(props) {
    super(props);
    modalRoot.style.display = 'block';

    this.el = document.createElement('div');
    modalRoot.appendChild(this.el);

    window.modals.push({
      element: this.el,
      hide: this.props.onHide || (() => {}),
    });

    this.previousBodyHeight = document.body.style.height;

    document.body.style.height = '100vh';
    document.body.style.overflowY = 'hidden';
  }

  componentWillUnmount() {
    _.remove(window.modals, (m) => m.element === this.el);

    document.body.style.height = this.previousBodyHeight;
    document.body.style.overflowY = 'auto';

    modalRoot.removeChild(this.el);

    if (!modalRoot.childNodes.length) {
      modalRoot.style.display = 'none';
    }
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.el);
  }
}

import React from 'react';
import Notifications from './containers/Notifications';

import Setup from './containers/Setup';

import WisevoiceRouter from './WisevoiceRouter';
import LoadingDimmer from './pages/Flow/components/LoadingDimmer/LoadingDimmer';

const App = () => {
  return (
    <Notifications>
      <Setup>
        <LoadingDimmer />
        <WisevoiceRouter />
      </Setup>
    </Notifications>
  );
};

export default App;

import React, { Component } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import DrawerRight from '../Dialog';
import Tabs from '../../../../components/common/TabBar';
import ConnectionsWrapper from 'pages/Flow/containers/ConnectionsWrapper';
import CodeEditor from './CodeEditor';
import ModalC from '../ModalContainer';
import Axios from 'axios';
import withNotifications from 'components/withNotifications';

import './ScriptDialog.scss';
import DeleteButton from '../Canvas/components/NodeSettingsProvider/components/components/DeleteButton';
import InputText from '../../../../components/common/input/InputText';
import languageMap from '../../../../Language/Language';

const input_style = {
  flex: '1 0 auto',
  height: 37,
  borderRadius: 4,
};
class ScriptDialog extends Component {
  state = {
    showCode: false,
  };
  debouncedNameSync = debounce(async () => {
    const { appId, projectId, script, nodeId, onNameChange } = this.props;
    const params = {
      app_id: appId,
      script_id: script,
      project_id: projectId,
      name: this.state.name,
    };

    await Axios.put('/internal_api/script', params);
    onNameChange(nodeId, this.state.name);
  }, 500);

  constructor(props) {
    super(props);
    this.state.name = this.props.objectName;
  }

  get code() {
    return get(this.props, 'script.javascript') || '';
  }

  get scriptId() {
    return get(this.props, 'script.script_id') || '';
  }

  deleteNode = () => {
    this.props.onClose();
    this.props.deleteNode(this.props.nodeId);
  };

  nameChanged = (e) => {
    this.setState({
      name: e.target.value,
    });
  };

  nameSave = () => {
    this.debouncedNameSync();
  };

  textareaClicked = () => {
    this.setState({
      showCode: true,
    });
  };

  closeEditor = () => {
    this.setState({
      showCode: false,
    });
  };

  handleCodeChange = (text) => {
    const { showError, script, projectId, appId, updateObject } = this.props;
    Axios.put('/internal_api/script', {
      app_id: appId,
      script_id: this.scriptId,
      project_id: projectId,
      code: text,
    }).catch(() => {
      showError('Something went wrong, please try again later.');
      updateObject('script', script);
    });

    const newScript = { ...script, javascript: text || '' };

    updateObject('script', newScript);

    this.setState({
      showCode: false,
    });
  };

  render() {
    const { objectName, onClose, nodeId } = this.props;

    return (
      <DrawerRight
        onClose={onClose}
        headerView={
          <>
            <InputText
              style={input_style}
              autoComplete="off"
              type="text"
              defaultValue={objectName}
              onChange={this.nameChanged}
              onBlur={this.nameSave}
            />
            <DeleteButton
              title={languageMap.deleteScript}
              onClick={this.props.deleteNode ? this.deleteNode : null}
            />
          </>
        }
        contentView={
          <div className="ScriptDialog">
            <Tabs>
              <div label={languageMap.settings}>
                <p>{languageMap.scriptCode}</p>
                <p>{languageMap.scriptHelperText}</p>
                <textarea onClick={this.textareaClicked} value={this.code} onChange={null} />
                {this.state.showCode && (
                  <ModalC>
                    <div className="ScriptDialog_Modal">
                      <div className="ScriptDialog_Modal_Backdrop" />
                      <CodeEditor
                        showClose={true}
                        onCancel={this.closeEditor}
                        onSave={this.handleCodeChange}
                        value={this.code}
                      />
                    </div>
                  </ModalC>
                )}
              </div>
              {this.props.hideConnections ? (
                <div label={languageMap.connectionsLabel}>{languageMap.connectionsHide}</div>
              ) : (
                <div label={languageMap.connectionsLabel}>
                  <ConnectionsWrapper nodeId={nodeId} />
                </div>
              )}
            </Tabs>
          </div>
        }
      />
    );
  }
}

export default withNotifications(ScriptDialog);

import { differenceInDays, differenceInHours, differenceInMinutes } from 'date-fns';

const getElapsedTime = (lastEventTimestamp) => {
  let deltaTime = differenceInMinutes(Date.now(), lastEventTimestamp);

  if (deltaTime < 60) {
    deltaTime = differenceInMinutes(Date.now(), lastEventTimestamp) + ' min';
  } else if (deltaTime >= 60 && deltaTime < 1440) {
    deltaTime = differenceInHours(Date.now(), lastEventTimestamp) + ' h';
  } else if (deltaTime >= 1440) {
    deltaTime = differenceInDays(Date.now(), lastEventTimestamp) + ' d';
  }
  return deltaTime;
};
export default getElapsedTime;

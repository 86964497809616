import { createRoot } from 'react-dom/client';
import React from 'react';

import * as Sentry from '@sentry/react';
import App from './App';
import isEmpty from 'lodash/isEmpty';
import store from 'store';
import { Provider } from 'react-redux';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { StyledEngineProvider } from '@mui/system';

import 'semantic-ui-css/semantic.min.css';
import './sass/app.scss';
import './GlobalFonts.scss';
import mainTheme from './mainTheme';
import { insertImageModal } from 'pages/Flow/components/ModalContainer/ImageModal';

import getAssetPath from './utils/getAssetPath';

const sentryDsn =
  process.env.NODE_ENV === 'production'
    ? 'https://433aae54e0784617b359744029bd56b7@o457806.ingest.sentry.io/5454327'
    : '';
Sentry.init({
  dsn: sentryDsn,
  environment: process.env.REACT_APP_ENV || 'dev',
  tracesSampleRate: 1.0, // raport pt cate erori sa trimita din numarul total
});

window.delayed_update = (timeout_name, callback, delay = 300) => {
  timeout_name += '_timeout_id';

  if (window[timeout_name]) {
    window.clearTimeout(window[timeout_name]);
  }

  window[timeout_name] = window.setTimeout(callback, delay);
};

window.modals = [];
document.body.addEventListener(
  'mousedown',
  (e) => {
    if (isEmpty(window.modals)) {
      return;
    }

    let element = e.target;
    let latest_modal = window.modals[window.modals.length - 1];

    while (element.parentNode) {
      if (element === latest_modal.element) {
        return;
      }

      element = element.parentNode;
    }

    latest_modal.hide();
  },
  true
);

insertImageModal();

export const TECTU_DOMAINS = [
  'https://chat.tectu.io',
  'https://chat-test.assuta.co.il',
  'https://chat.assuta.co.il',
];
let title = 'Wisevoice';
const favicon = document.getElementById('faviconId');
TECTU_DOMAINS.forEach((domain) => {
  if (window.location.href.includes(domain)) {
    title = 'Tectu';

    favicon.setAttribute('href', getAssetPath('faviconTectu.png'));
  }
});
export const DOCUMENT_TITLE = title;
if (title === 'Wisevoice') {
  favicon.setAttribute('href', getAssetPath('favicon.ico'));
}
window.addEventListener('load', () => {
  document.title = DOCUMENT_TITLE;
});

const muiTheme = createTheme(mainTheme);

const rootElement = document.getElementById('global-root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={muiTheme}>
        <App />
      </ThemeProvider>
    </StyledEngineProvider>
  </Provider>
  // document.getElementById('wisevoice-root')
);

import React, { useContext } from 'react';
import { connect } from 'react-redux';

import ConversationSummary from 'pages/CRM/components/ConversationDetails/ConversationSummary';
import UserProfileContainer from 'pages/CRM/components/ConversationDetails/UserProfileContainer';
import ExternalUserProfile from 'pages/CRM/components/ConversationDetails/ExternalUserProfile';
import InternalUserProfile from 'pages/CRM/components/ConversationDetails/InternalUserProfile';
import languageMap from 'Language/Language';

import {
  selectInboxCustomData,
  selectCurrentConversation,
  selectFirstSeen,
  selectInboxFolder,
  selectIsMobile,
} from 'store/crm/selectors';

import './ConversationDetails.scss';

import { displayConversationContext } from 'containers/ConversationsInboxContainer/ConversationsInboxContainer';

const ConversationDetails = ({
  isMobile,
  conversation,
  firstSeen,
  inboxFolder: { submenu },
  inboxCustomData,
  handleConversationDetailsButtonClick,
  ...props
}) => {
  const { setDisplayConversationDetails } = useContext(displayConversationContext);

  const returnBackToConversationScreen = () => {
    setDisplayConversationDetails(false);
  };

  return (
    <div className="ConversationDetails">
      {isMobile && <div className="return-button-container"></div>}
      {submenu === 'External' && (
        <ConversationSummary
          returnBackToConversationScreen={returnBackToConversationScreen}
          title={languageMap.conversationDetails}
          handleConversationDetailsButtonClick={handleConversationDetailsButtonClick}
        />
      )}
      <UserProfileContainer>
        {submenu === 'External' && (
          <ExternalUserProfile
            conversation={conversation}
            firstSeen={firstSeen}
            inboxCustomData={inboxCustomData}
            returnBackToConversationScreen={returnBackToConversationScreen}
          />
        )}
        {submenu === 'Internal' && <InternalUserProfile />}
      </UserProfileContainer>
    </div>
  );
};

const mapStateToProps = (state) => ({
  conversation: selectCurrentConversation(state),
  inboxCustomData: selectInboxCustomData(state),
  firstSeen: selectFirstSeen(state),
  inboxFolder: selectInboxFolder(state),
  isMobile: selectIsMobile(state),
});

export default connect(mapStateToProps, null)(ConversationDetails);

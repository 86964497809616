import React, { Component } from 'react';
import Axios from 'axios';
import { produce } from 'immer';
import Loader from 'components/common/Loader';
import _ from 'lodash';
import { Dropdown } from 'semantic-ui-react';

import DrawerRight from 'pages/Flow/components/Dialog';
import NameInput from 'pages/Flow/components/NameInput';
import Tabs from 'components/common/TabBar';
import Sections from 'components/common/Sections';
import LabeledCheckbox from 'components/common/LabeledCheckbox';
import DeleteButton from '../components/DeleteButton';
import CallcenterErrorHandlingMessages from 'pages/Flow/components/CallcenterErrorHandlingMessages';
import MessageSuggestions from 'pages/Flow/components/Message/MessageSuggestions';
import Utterances from 'components/Utterances';
import ConnectionsWrapper from 'pages/Flow/containers/ConnectionsWrapper';
import MessageBlocks from 'pages/Flow/components/Message/MessageBlocks';
import languageMap from 'Language/Language';

import './EntityDialog.scss';
import MessageOptions from '../../../../../Message/MessageOptions';

export default class EntityDialog extends Component {
  deleteNode = () => {
    this.props.onClose();
    this.props.deleteNode(this.props.nodeId);
  };

  constructor(props) {
    super(props);

    this.types = [];

    this.props.entityTypes.forEach((row) => {
      this.types.push({
        value: row.entity_type_id,
        text: row.entity_type_name,
      });
    });

    this.state = {
      isLoading: true,
      isLoadingRequest: false,
      isList: true,
      isRequired: true,
      isIvrEnabled: true,
      isValidationRequired: false,
      defaultValue: '',
      type: 'system_date',
      selectedLanguage: '',

      prompt: {
        automaticTranslation: true,
        responseOptions: [],
        responseOptionsByLanguage: {},
        replySuggestions: [],
        replySuggestionsByLanguage: {},
        timeoutResponseOptions: [],
        timeoutResponseOptionsByLanguage: {},
        min_listen_time: 1,
        no_response_timeout: 1.1,
        time_exceeded: 10,
      },

      errorResponse: {
        responseOptions: [],
        responseOptionsByLanguage: {},
        timeoutResponseOptionsByLanguage: {},
        min_listen_time: 1,
        no_response_timeout: 1.1,
        time_exceeded: 10,
      },

      validation: {
        responseOptions: [],
        responseOptionsByLanguage: {},
        replySuggestions: [],
        replySuggestionsByLanguage: {},
        timeoutResponseOptions: [],
        min_listen_time: 1,
      },

      reprompt: {
        responseOptions: [],
        responseOptionsByLanguage: {},
        replySuggestions: [],
        replySuggestionsByLanguage: {},
        timeoutResponseOptions: [],
        min_listen_time: 1,
      },

      utterances_by_language: {},

      irvEnable: false,
    };

    this.debouncedSync = _.debounce(this.syncStateWithServer, 500);
  }

  componentDidMount() {
    try {
      Axios.get('/internal_api/entity', {
        params: { app_id: this.props.appId, entity_id: this.props.entityId },
      }).then((response) => {
        const newState = this.extractSettings(response);
        this.setState({
          ...newState,
          selectedLanguage: this.props.selectedLanguage,
          isLoading: false,
        });
      });
      Axios.get('/internal_api/project/integration/data', {
        params: {
          project_id: this.props.projectId,
          type_id: 2,
        },
      }).then((response) => {
        let data = JSON.parse(response.data.data);

        this.setState(
          produce((draft) => {
            draft.voiceInteraction = data.voice_interaction;
          })
        );
      });
    } catch {
      this.setState({ isLoading: false });
    }
  }

  extractSettings = (response) => {
    const { message_details: settings, utterances_by_language } = response.data;

    const newState = { ...this.state };

    const promptData = settings.prompt_message_properties;
    const errorResponseData = settings.error_message_properties;
    const validationData = settings.validation_message_properties;
    const repromptData = settings.re_prompt_message_properties;

    if (promptData !== null) {
      newState.prompt = promptData;
      newState.prompt.timeoutResponseOptionsByLanguage =
        newState.prompt.timeoutResponseOptionsByLanguage || {};
    }

    if (errorResponseData !== null) {
      newState.errorResponse = errorResponseData;
      newState.errorResponse.timeoutResponseOptionsByLanguage =
        newState.errorResponse.timeoutResponseOptionsByLanguage || {};
    }

    if (validationData !== null) {
      newState.validation = validationData;
    }

    if (repromptData !== null) {
      newState.reprompt = repromptData;
    }
    newState.isList = Boolean(settings.is_list);
    newState.isRequired = Boolean(settings.is_required);
    newState.isIvrEnabled = Boolean(settings.enable_ivr);
    newState.isValidationRequired = Boolean(settings.is_validation_required);
    newState.type = settings.entity_type_id;

    return {
      ...newState,
      utterances_by_language,
    };
  };

  onMessageOptionByLanguageChange = (element, key) => (value) => {
    this.setState(
      produce((draft) => {
        draft[element][key] = {
          ...draft[element][key],
          [draft.selectedLanguage]: value,
        };
      }),
      () => {
        this.debouncedSync(this.state.prompt.automaticTranslation);
      }
    );
  };

  handleCallParameterChange = (element) => (key, value) => {
    this.setState(
      produce((draft) => {
        draft[element][key] = value;
      }),
      () => {
        this.debouncedSync();
      }
    );
  };

  handleIsListChange = () => {
    this.setState(
      produce((draft) => {
        draft.isList = !draft.isList;
      }),
      () => {
        this.debouncedSync();
      }
    );
  };

  handleIsRequiredChange = () => {
    this.setState(
      produce((draft) => {
        draft.isRequired = !draft.isRequired;
      }),
      () => {
        this.debouncedSync();
      }
    );
  };

  handleIsValidationRequiredChange = () => {
    this.setState(
      produce((draft) => {
        draft.isValidationRequired = !draft.isValidationRequired;
      }),
      () => {
        this.debouncedSync();
      }
    );
  };

  handleTypeChange = (e, { value }) => {
    this.setState(
      {
        type: value,
      },
      () => {
        this.debouncedSync();
      }
    );
  };

  syncStateWithServer = (doTranslate) => {
    let params = {
      entity_id: this.props.entityId,
      properties: { ...this.state, isLoading: undefined },
    };

    // console.log('[Entity] Sync state with server! ', params);

    const URL = `/internal_api/entity?translate=${!!doTranslate}`;
    Axios.put(URL, params).then((response) => {
      if (doTranslate) {
        const newState = this.extractSettings(response);
        this.setState(newState);
      }
    });
  };

  onMessageBlockOptionChanged = (key, element) => (value) => {
    this.setState(
      produce((draft) => {
        draft[key][element] = value;
      }),
      () => {
        this.debouncedSync();
      }
    );
  };

  onChangeLanguage = (e, { value }) => {
    this.setState({ selectedLanguage: value });
  };
  loadingChange = (value) => {
    this.setState({ isLoadingRequest: value });
  };
  updateUtterances = (utterances, has_more) => {
    const { selectedLanguage } = this.state;
    this.setState((draft) => {
      const utterancesData = draft.utterances_by_language[selectedLanguage];
      utterancesData.has_more = has_more !== undefined ? has_more : utterancesData.has_more;
      utterancesData.utterances = utterances;
    });
  };

  render() {
    const {
      validation,
      reprompt,
      isLoading,
      type,
      isList,
      isRequired,
      prompt,
      voiceInteraction,
      errorResponse,
      isValidationRequired,
      utterances_by_language,
      selectedLanguage,
      isLoadingRequest,
    } = this.state;

    const utterancesData = utterances_by_language[selectedLanguage] || {};

    const {
      appId,
      entityId,
      nodeId,
      onNameChange,
      objectName,
      onClose,
      entities,
      deleteNode,
      languageOptions,
      projectId,
    } = this.props;
    let validationUI;
    let repromptUi;

    if (isValidationRequired) {
      validationUI = (
        <div>
          <MessageOptions
            title={languageMap.entityValidationMessages}
            description={`${languageMap.usedToReadTheEntityBack} ${languageMap.youCanInputDifferentTextResponsesBelow}`}
            options={validation.responseOptionsByLanguage[selectedLanguage] || []}
            onChange={this.onMessageOptionByLanguageChange(
              'validation',
              'responseOptionsByLanguage'
            )}
            selectedLanguage={selectedLanguage}
          />
          <MessageSuggestions
            suggestions={validation.replySuggestionsByLanguage[selectedLanguage] || []}
            selectedLanguage={selectedLanguage}
            onSuggestionsChange={this.onMessageOptionByLanguageChange(
              'validation',
              'replySuggestionsByLanguage'
            )}
          />
        </div>
      );
      repromptUi = (
        <div>
          <MessageOptions
            title={languageMap.entityRepromptMessages}
            description={`${languageMap.usedIfTheUserRespondsNegatively} ${languageMap.youCanInputDifferentTextResponsesBelow}`}
            options={reprompt.responseOptionsByLanguage[selectedLanguage] || []}
            onChange={this.onMessageOptionByLanguageChange('reprompt', 'responseOptionsByLanguage')}
            selectedLanguage={selectedLanguage}
          />
          <MessageSuggestions
            suggestions={reprompt.replySuggestionsByLanguage[selectedLanguage] || []}
            selectedLanguage={selectedLanguage}
            onSuggestionsChange={this.onMessageOptionByLanguageChange(
              'reprompt',
              'replySuggestionsByLanguage'
            )}
          />
        </div>
      );
    }

    return (
      <DrawerRight
        isLoading={isLoadingRequest}
        onClose={onClose}
        headerView={
          <>
            <NameInput
              appId={appId}
              id={entityId}
              type="entity"
              nodeId={nodeId}
              onNameChange={onNameChange}
              value={objectName}
            />
            {languageOptions.length > 1 && (
              <Dropdown
                className="languageDropdown"
                selection
                value={selectedLanguage}
                options={languageOptions}
                onChange={this.onChangeLanguage}
              />
            )}
            <DeleteButton
              title={languageMap.deleteEntity}
              type="entity"
              onClick={deleteNode ? this.deleteNode : null}
            />
          </>
        }
        contentView={
          isLoading ? (
            <Loader />
          ) : (
            <div className="EntityDialog">
              <Tabs>
                <div label={languageMap.chatBotLabel}>
                  <Sections>
                    <Dropdown
                      value={type}
                      selection
                      options={this.types}
                      onChange={this.handleTypeChange}
                    />
                    <div className="EntityDialog_LabeledCheckboxContainer">
                      <LabeledCheckbox
                        label={languageMap.entityIsList}
                        checked={isList}
                        onChange={this.handleIsListChange}
                      />
                      <LabeledCheckbox
                        label={languageMap.entityIsRequired}
                        checked={isRequired}
                        onChange={this.handleIsRequiredChange}
                      />
                    </div>

                    <div>
                      <p className="EntityDialog_Title">{languageMap.entityPromptMessages}</p>
                      <MessageBlocks
                        projectId={projectId}
                        hideTitle
                        options={prompt.responseOptionsByLanguage[selectedLanguage] || []}
                        voiceInteraction={voiceInteraction}
                        onChange={this.onMessageOptionByLanguageChange(
                          'prompt',
                          'responseOptionsByLanguage'
                        )}
                        automaticTranslation={prompt.automaticTranslation}
                        onChangeAutomaticTranslation={this.onMessageBlockOptionChanged(
                          'prompt',
                          'automaticTranslation'
                        )}
                        selectedLanguage={selectedLanguage}
                        loadingChange={this.loadingChange}
                      />
                      <MessageSuggestions
                        suggestions={prompt.replySuggestionsByLanguage[selectedLanguage] || []}
                        onSuggestionsChange={this.onMessageOptionByLanguageChange(
                          'prompt',
                          'replySuggestionsByLanguage'
                        )}
                        selectedLanguage={selectedLanguage}
                      />
                    </div>

                    <div>
                      <p className="EntityDialog_Title">{languageMap.entityErrorMessages}</p>
                      <MessageBlocks
                        loadingChange={this.loadingChange}
                        projectId={projectId}
                        hideTitle
                        options={errorResponse.responseOptionsByLanguage[selectedLanguage] || []}
                        voiceInteraction={voiceInteraction}
                        selectedLanguage={selectedLanguage}
                        onChange={this.onMessageOptionByLanguageChange(
                          'errorResponse',
                          'responseOptionsByLanguage'
                        )}
                      />
                    </div>

                    <div className="EntityDialog_UtterancesContainer">
                      <Utterances
                        hasMore={utterancesData.has_more}
                        appId={appId}
                        entities={entities}
                        entityId={entityId}
                        utterances={utterancesData.utterances || {}}
                        selectedLanguage={selectedLanguage}
                        updateUtterances={this.updateUtterances}
                      />
                    </div>
                  </Sections>
                </div>
                <div label={languageMap.callCenterLabel}>
                  <Sections>
                    <div className="EntityDialog_LabeledCheckboxContainer">
                      <LabeledCheckbox
                        label={languageMap.entityIsRequired}
                        checked={isRequired}
                        onChange={this.handleIsRequiredChange}
                      />
                    </div>
                    <CallcenterErrorHandlingMessages
                      title={languageMap.entityPromptMessageTimeout}
                      description={languageMap.youCanInputDifferentTextResponsesBelow}
                      timeoutResponseOptions={
                        prompt.timeoutResponseOptionsByLanguage[selectedLanguage] || []
                      }
                      selectedLanguage={selectedLanguage}
                      minListenTime={prompt.min_listen_time !== null ? prompt.min_listen_time : 1}
                      noResponseTimeout={
                        prompt.no_response_timeout !== null ? prompt.no_response_timeout : 1.1
                      }
                      timeExceeded={prompt.time_exceeded !== null ? prompt.time_exceeded : 10}
                      onParameterChange={this.handleCallParameterChange('prompt')}
                      onMessageOptionsChange={this.onMessageOptionByLanguageChange(
                        'prompt',
                        'timeoutResponseOptionsByLanguage'
                      )}
                    />
                    <MessageSuggestions
                      suggestions={prompt.replySuggestions}
                      onSuggestionsChange={this.onMessageOptionByLanguageChange(
                        'prompt',
                        'replySuggestionsByLanguage'
                      )}
                    />
                    <CallcenterErrorHandlingMessages
                      title={languageMap.entityErrorMessageTimeout}
                      description={languageMap.youCanInputDifferentTextResponsesBelow}
                      timeoutResponseOptions={
                        errorResponse.timeoutResponseOptionsByLanguage[selectedLanguage] || []
                      }
                      minListenTime={
                        errorResponse.min_listen_time !== null ? errorResponse.min_listen_time : 1
                      }
                      selectedLanguage={selectedLanguage}
                      noResponseTimeout={
                        errorResponse.no_response_timeout !== null
                          ? errorResponse.no_response_timeout
                          : 1.1
                      }
                      timeExceeded={
                        errorResponse.time_exceeded !== null ? errorResponse.time_exceeded : 10
                      }
                      onParameterChange={this.handleCallParameterChange('errorResponse')}
                      onMessageOptionsChange={this.onMessageOptionByLanguageChange(
                        'errorResponse',
                        'timeoutResponseOptionsByLanguage'
                      )}
                    />
                    <div className="EntityDialog_LabeledCheckboxContainer">
                      <LabeledCheckbox
                        label={languageMap.entityUserValidation}
                        checked={isValidationRequired}
                        onChange={this.handleIsValidationRequiredChange}
                      />
                    </div>
                    {validationUI}
                    {repromptUi}
                  </Sections>
                </div>
                {this.props.hideConnections ? (
                  <div label={languageMap.connectionsLabel}>{languageMap.connectionsHide}</div>
                ) : (
                  <div label={languageMap.connectionsLabel}>
                    <ConnectionsWrapper nodeId={this.props.nodeId} />
                  </div>
                )}
              </Tabs>
            </div>
          )
        }
      />
    );
  }
}

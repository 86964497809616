const IMAGE_ID = 'modal-root-source-image';
const MODAL_ID = 'image-modal-root-id';

let previousBodyHeight;
let previousBodyOverflow;
export const openImageModal = (imageUrl) => {
  const image = document.getElementById(IMAGE_ID);
  image.src = imageUrl;
  document.getElementById(MODAL_ID).style.display = 'flex';
  previousBodyHeight = document.body.style.height;
  previousBodyOverflow = document.body.style.overflowY;
  document.body.style.height = '100vh';
  document.body.style.overflowY = 'hidden';
};

const closeImageModal = () => {
  const image = document.getElementById(IMAGE_ID);
  image.src = '';
  document.getElementById(MODAL_ID).style.display = 'none';
  document.body.style.height = previousBodyHeight;
  document.body.style.overflowY = previousBodyOverflow;
};

export const insertImageModal = () => {
  const prevModal = document.getElementById(MODAL_ID);
  if (prevModal) {
    return;
  }
  const modalRoot = document.createElement('div');
  modalRoot.id = MODAL_ID;
  modalRoot.style =
    'z-index:2147483647; position: fixed; width: 100%; height: 100%; left: 0; top: 0; justify-content: center; align-items: center; background-color: rgb(0 0 0 / 0.9); display: none';
  document.body.appendChild(modalRoot);

  modalRoot.onclick = closeImageModal;

  const img = document.createElement('img');
  img.id = IMAGE_ID;
  img.style.maxHeight = '98%'; // auto
  img.style.maxWidth = '98%'; // auto
  // img.style.width = '100%';

  modalRoot.appendChild(img);
};

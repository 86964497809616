import React, { useContext, useState } from 'react';
import Axios from 'axios';
import ContextMenu from 'components/ContextMenu';
import ModalC from 'pages/Flow/components/ModalContainer';
import { AddButton } from 'components/common/Button/Buttons.js';
import { NodeUpdater, FlowMetaContext } from 'pages/Flow/utils/canvas-contexts';
import createObject from 'pages/Flow/Services/CreateObjectService/CreateObjectService';

const CreateEntryPoint = () => {
  const [showMenu, setShowMenu] = useState(false);

  const appData = useContext(FlowMetaContext);
  const { updateOrCreateNode } = useContext(NodeUpdater);

  const getNewNodeCoordinates = () => {
    return {
      x: 50,
      y: 200,
    };
  };

  const createNode = (objectId, objectClass) => {
    const { appId } = appData;

    return Axios.post('/internal_api/create_node', {
      source_node_id: null,
      app_id: appId,
      object_class: objectClass,
      object_id: objectId,
      ...getNewNodeCoordinates(),
    }).then((resp) => resp.data);
  };

  /**
   * @param {object} selection
   * Creates a new object of selected class if 'Create new' is selected
   * Creates new node with selected object id or the newly created
   */
  const handleOptionSelect = async (selection) => {
    try {
      const objectClass = selection.objectClass;

      const { appId, projectId, addObjectToState } = appData;

      const objectIdPromise = () => {
        return createObject(objectClass, appId, projectId).then((data) => {
          // save new objects in the state
          addObjectToState(objectClass, data.object);
          return data.objectId;
        });
      };

      const objectId = await objectIdPromise();
      const nodeConnectionData = await createNode(objectId, objectClass);
      const { id: node_id } = nodeConnectionData;
      const newNode = {
        object_class: objectClass,
        object_id: objectId,
        ...getNewNodeCoordinates(),
        next: {},
        prev: [],
      };
      updateOrCreateNode({
        [node_id]: newNode,
      });
      setShowMenu(null);
    } catch (error) {
      console.log('Could not create node', error);
    }
  };

  const data = [
    {
      label: 'New Event',
      returnValue: { objectClass: 'event' },
    },
    {
      label: 'Child Connector',
      returnValue: { objectClass: 'flow_connector' },
    },
  ];

  const handleClick = (e) => {
    setShowMenu(!showMenu && { x: e.pageX, y: e.pageY });
  };

  return (
    <>
      <AddButton
        style={{
          backgroundColor: '#2d4b8f',
          boxShadow: '0px 1px 5px 1px gray',
        }}
        text="Create Entrypoint"
        icon="add"
        onClick={handleClick}
      />
      {showMenu && (
        <ModalC
          onHide={() => {
            setShowMenu(null);
          }}
        >
          <ContextMenu
            left={showMenu.x}
            top={showMenu.y}
            options={data}
            onSelect={handleOptionSelect}
            // hasSearch={true}
          />
        </ModalC>
      )}
    </>
  );
};

export default CreateEntryPoint;
